export type Pronouns =
  | "he_him"
  | "she_her"
  | "they_them"
  | "ze_hir"
  | "he_they"
  | "she_they";

const PronounsMap: Record<Pronouns, string> = {
  she_her: "She/Her",
  he_him: "He/Him",
  they_them: "They/Them",
  ze_hir: "Ze/Hir",
  he_they: "He/They",
  she_they: "She/They",
};

interface PronounsOption {
  value: Pronouns;
  label: string;
}

export const pronounsOptions: PronounsOption[] = Object.entries(
  PronounsMap
).map(([k, v]) => ({ value: k as Pronouns, label: v }));

export const pronounsLabelForValue = (pronouns: Pronouns | undefined) => {
  if (!pronouns) {
    return "";
  }

  return PronounsMap[pronouns];
};
