import { Details } from "../interfaces/Details";
import { Measurements } from "../interfaces/Measurements";
import { Appearance } from "../interfaces/Appearance";
import { Media } from "../interfaces/Media";

interface Store {
  details?: Details;
  measurements?: Measurements;
  appearance?: Appearance;
  mediaV2?: Media;
}

const namespace = (key: string) => `agency.contact.apply.${key}`;

export function toStore<K extends keyof Store>(key: K, thing: Store[K]): void {
  localStorage.setItem(namespace(key), JSON.stringify(thing));
}

export function fromStore<K extends keyof Store>(key: K): Store[K] {
  const thing = localStorage.getItem(namespace(key));

  if (thing === null) {
    return undefined;
  }

  return JSON.parse(thing);
}

export function isStored(key: keyof Store): boolean {
  return localStorage.getItem(namespace(key)) !== null;
}

export function clearStore() {
  localStorage.clear();
}

export function normalizeStore() {
  removeExpiredAssets();
}

function removeExpiredAssets() {
  const media = fromStore("mediaV2");

  if (!media) {
    return;
  }

  const keys = Object.keys(media) as Extract<keyof Media, string>[];

  keys.forEach((key) => {
    const asset = media[key];

    if (!asset) {
      return;
    }

    const validUntil = new Date(asset.validUntil);
    const now = new Date();
    const hasExpired = validUntil <= now;

    if (!hasExpired) {
      return;
    }

    const newMedia: Media = {
      ...fromStore("mediaV2"),
      [key]: undefined,
    };

    toStore("mediaV2", newMedia);
  });
}
