import React from "react";
import styled from "styled-components";
import { HorizontalConstrainer } from "./constrainers/HorizontalConstrainer";
import { Link } from "@reach/router";

const inverted = false;
const foregroundByte = inverted ? 255 : 0;
const backgroundByte = inverted ? 0 : 255;
const foregroundRgb = `rgb(${foregroundByte}, ${foregroundByte}, ${foregroundByte})`;
const backgroundRgb = `rgb(${backgroundByte}, ${backgroundByte}, ${backgroundByte})`;

const Wrapper = styled.header`
  height: 65px;
  overflow-y: hidden;
  border-bottom: 2px #000 solid;
  background-color: ${backgroundRgb};
  color: ${foregroundRgb};
  position: relative;
  z-index: 1337;
`;

const ContentContainer = styled(HorizontalConstrainer)`
  height: 100%;
  display: flex;
  align-items: center;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const SiteName = styled.span`
  color: #000;
  font-weight: bold;
  margin-left: 0.5rem;
  font-size: 0.7rem;
`;

const Logo: React.FC = () => (
  <svg
    width="24"
    height="27"
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2 4.74698V15.5972H4.8V4.74698H19.2ZM24 0H0V27H24V0Z"
      fill={foregroundRgb}
    />
  </svg>
);

export const Header: React.FC = () => (
  <Wrapper>
    <ContentContainer>
      <LogoLink to="/">
        <Logo />
        <SiteName>Apply</SiteName>
      </LogoLink>
    </ContentContainer>
  </Wrapper>
);
