import React, { useState } from "react";
import styled from "styled-components";
import { RouteComponentProps, navigate, Redirect } from "@reach/router";
import { fromStore } from "../util/store";
import { StepSummary } from "../components/structural/StepSummary";
import { H1, H2 } from "../components/type/Heading";
import { Lede } from "../components/type/Lede";
import { Button } from "../components/clickables/Button";
import { genderLabelForValue } from "../data/gender";
import { pronounsLabelForValue } from "../data/pronouns";
import {
  complexionLabelForValue,
  eyeColourLabelForValue,
  hairStyleLabelForValue,
  hairColourLabelForValue,
} from "../data/appearance";
import { HorizontalConstrainer } from "../components/structural/constrainers/HorizontalConstrainer";
import { CREATE_TALENT_APPLICATION_MUTATION } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import {
  Asset,
  Complexion,
  EyeColour,
  Gender,
  HairColour,
  HairStyle,
  MutationCreateTalentApplicationArgs,
  Pronouns,
} from "../graphql/types";
import { computeLastStep } from "../util/validation";

const Label = styled.div`
  font-weight: bold;
`;

const FieldWrap = styled.div`
  margin: 0 0 1.5rem;
  width: 48%;
  display: inline;
  float: left;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const MediaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MediaWrap = styled(FieldWrap)`
  width: 48%;
  &:nth-child(odd) {
    margin-right: 2%;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Answer = styled.div`
  margin-bottom: 1rem;
`;

const SubmitInfo = styled.div`
  padding-bottom: 1.5rem;
`;

const ErrorMessage = styled(SubmitInfo)`
  padding: 0.5rem 0.75rem;
  color: #fff;
  background-color: #c00;
  font-size: 0.75rem;
  margin-bottom: 1rem;

  strong {
    display: block;
    margin-bottom: 0.25rem;
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 1rem;

  @media (max-width: 800px) {
    display: block;
  }
`;

const Column = styled.div`
  width: 50%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Wrapper = styled(HorizontalConstrainer)`
  padding-bottom: 2rem;
`;

export const SummaryPage: React.FC<RouteComponentProps> = () => {
  const [loading, setLoading] = useState(false);

  const [createTalentApplication, { error }] =
    useMutation<MutationCreateTalentApplicationArgs>(
      CREATE_TALENT_APPLICATION_MUTATION
    );

  const details = fromStore("details");
  const measurements = fromStore("measurements");
  const appearance = fromStore("appearance");
  const media = fromStore("mediaV2");

  const lastStep = computeLastStep(appearance, details, measurements, media);
  if (!(media && appearance && measurements && details) ||
      lastStep !== "/summary") {
    return <Redirect to={lastStep} noThrow />;
  }

  const [day, month, year] = details.dateOfBirth.split("/");
  const formattedDob = `${year}-${month}-${day}`;

  const formData: MutationCreateTalentApplicationArgs = {
    name: details.name,
    email: details.email,
    location: details.location,
    city: details.city,
    dateOfBirth: formattedDob,
    gender: (details.gender as string).toUpperCase() as Gender,
    hobbies: details.hobbies,
    biography: details.biography || "",
    instagramUsername: details.instagramUsername,
    height: measurements.height,
    waistSize: measurements.waistSize,
    legLength: measurements.legLength,
    shoeSize: measurements.shoeSize,
    hipSize: measurements.hipSize,
    bustSize: measurements.bustSize,
    chestSize: measurements.chestSize,
    suitSize: measurements.suitSize,
    complexion: (appearance.complexion as string)
      .toUpperCase()
      .replace("-", "_") as Complexion,
    eyeColour: (appearance.eyeColour as string).toUpperCase() as EyeColour,
    hairColour: (appearance.hairColour as string).toUpperCase() as HairColour,
    hairStyle: (appearance.hairStyle as string).toUpperCase() as HairStyle,
    assetIds: Object.values(media).map((a: Asset) => a.id),
    pronouns: (details.pronouns as string).toUpperCase() as Pronouns,
  };

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      await createTalentApplication({
        variables: formData,
      });
      setLoading(false);
      navigate("/submitted");
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <StepSummary currentStep={5} />
      <H1>Summary</H1>
      <Lede>
        Great - that's everything we need. Double-check that everything below is
        correct, and then press submit at the bottom of this page.
      </Lede>

      <H2>Details</H2>

      <Row>
        <Column>
          <Label>Name</Label>
          <Answer>{details.name}</Answer>

          <Label>Email Address</Label>
          <Answer>{details.email}</Answer>

          <Label>Country</Label>
          <Answer>{details.location}</Answer>

          <Label>City</Label>
          <Answer>{details.city}</Answer>

          {details.biography && details.biography !== "" && (
            <>
              <Label>Biography</Label>
              <Answer>
                {details.biography.split("\n").map((t, i) => (
                  <p key={i}>{t}</p>
                ))}
              </Answer>
            </>
          )}
        </Column>

        <Column>
          <Label>Date of Birth</Label>
          <Answer>{details.dateOfBirth}</Answer>

          <Label>Gender</Label>
          <Answer>
            {genderLabelForValue(
              (details.gender as string)?.toUpperCase() as Gender
            )}
          </Answer>

          <Label>Pronouns</Label>
          <Answer>{pronounsLabelForValue(details.pronouns)}</Answer>

          {details.instagramUsername && details.instagramUsername !== "" && (
            <>
              <Label>Instagram username</Label>
              <Answer>@{details.instagramUsername}</Answer>
            </>
          )}

          {(details.hobbies || []).length > 0 && (
            <>
              <Label>Hobbies</Label>
              <Answer>{details.hobbies.join(", ")}</Answer>
            </>
          )}
        </Column>
      </Row>

      <H2>Measurements</H2>

      <Row>
        <Column>
          <Label>Height</Label>
          <Answer>{measurements.height}cm</Answer>

          <Label>Waist Size</Label>
          <Answer>{measurements.waistSize}cm</Answer>

          <Label>Leg Length</Label>
          <Answer>{measurements.legLength}cm</Answer>

          <Label>Shoe Size</Label>
          <Answer>EU {measurements.shoeSize}</Answer>

          <Label>Hip Size</Label>
          <Answer>{measurements.hipSize}cm</Answer>
        </Column>

        <Column>
          {details.gender !== Gender.Female && measurements.chestSize && (
            <>
              <Label>Chest Size</Label>
              <Answer>{measurements.chestSize}cm</Answer>
            </>
          )}

          {details.gender !== Gender.Female && measurements.suitSize && (
            <>
              <Label>Suit Size</Label>
              <Answer>{measurements.suitSize}cm</Answer>
            </>
          )}

          {details.gender !== Gender.Male && measurements.bustSize && (
            <>
              <Label>Bust Size</Label>
              <Answer>{measurements.bustSize}cm</Answer>
            </>
          )}
        </Column>
      </Row>

      <H2>Appearance</H2>

      <Row>
        <Column>
          <Label>Complexion</Label>
          <Answer>
            {complexionLabelForValue(
              (appearance.complexion as string)
                .replace("-", "_")
                .toUpperCase() as Complexion
            )}
          </Answer>

          <Label>Eye Colour</Label>
          <Answer>
            {eyeColourLabelForValue(
              (appearance.eyeColour as string).toUpperCase() as EyeColour
            )}
          </Answer>
        </Column>

        <Column>
          <Label>Hair Style</Label>
          <Answer>
            {hairStyleLabelForValue(
              (appearance.hairStyle as string).toUpperCase() as HairStyle
            )}
          </Answer>

          {appearance.hairColour && (
            <>
              <Label>Hair Colour</Label>
              <Answer>
                {hairColourLabelForValue(
                  (appearance.hairColour as string).toUpperCase() as HairColour
                )}
              </Answer>
            </>
          )}
        </Column>
      </Row>

      <H2>Media</H2>

      <MediaContainer>
        {media.fullLength && (
          <MediaWrap>
            <Label>Full Length</Label>
            <img src={media.fullLength.mediaUrl} alt="Full Length" />
          </MediaWrap>
        )}

        {media.waistUp && (
          <MediaWrap>
            <Label>Waist Up</Label>
            <img src={media.waistUp.mediaUrl} alt="Waist Up" />
          </MediaWrap>
        )}

        {media.closeUp && (
          <MediaWrap>
            <Label>Close Up</Label>
            <img src={media.closeUp.mediaUrl} alt="Close Up" />
          </MediaWrap>
        )}

        {media.profile && (
          <MediaWrap>
            <Label>Profile</Label>
            <img src={media.profile.mediaUrl} alt="Profile" />
          </MediaWrap>
        )}

        {media.showcase && (
          <MediaWrap>
            <Label>Showcase</Label>
            <video autoPlay loop>
              <source src={media.showcase.mediaUrl} />
            </video>
          </MediaWrap>
        )}
      </MediaContainer>

      {!loading && error && (
        <ErrorMessage>
          <strong>An error occurred while processing your submission.</strong>
          Please review your details and try again (
          {error.message.toLowerCase()}).
        </ErrorMessage>
      )}
      <Button onClick={handleClick} disabled={loading}>
        {loading ? "Loading..." : "Submit"}
      </Button>
    </Wrapper>
  );
};
