import React, { useState } from "react";
import { RouteComponentProps, navigate, Redirect } from "@reach/router";
import { fromStore, toStore, isStored } from "../util/store";
import { Appearance } from "../interfaces/Appearance";
import { Form } from "../components/form/Form";
import { H1 } from "../components/type/Heading";
import { Lede } from "../components/type/Lede";
import { Button } from "../components/clickables/Button";
import { RadioField } from "../components/form/RadioField";
import { StepSummary } from "../components/structural/StepSummary";
import {
  eyeColourOptions,
  hairStyleOptions,
  hairColourOptions,
  complexionOptions,
} from "../data/appearance";
import { HorizontalConstrainer } from "../components/structural/constrainers/HorizontalConstrainer";
import { HairStyle } from "../graphql/types";

export const AppearancePage: React.FC<RouteComponentProps> = () => {
  const [appearance, setAppearance] = useState<Appearance>(
    fromStore("appearance") || {
      complexion: null,
      eyeColour: null,
      hairColour: null,
      hairStyle: null,
    }
  );

  if (!isStored("measurements")) {
    return <Redirect to="/measurements" noThrow />;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    toStore("appearance", appearance);

    navigate("/media");
  };

  const changeHandlerFor = (fieldName: string) => (nextValue: string) =>
    setAppearance({ ...appearance, [fieldName]: nextValue });

  return (
    <HorizontalConstrainer>
      <StepSummary currentStep={3} />
      <H1>Appearance</H1>
      <Lede>
        We know it’s hard to describe how you look with only a few boxes to
        choose from. But try to pick the best options from the list below.
      </Lede>
      <Form onSubmit={handleSubmit}>
        <RadioField
          name="complexion"
          label="What's your complexion?"
          options={complexionOptions}
          value={appearance.complexion || ""}
          onChange={changeHandlerFor("complexion")}
          required
        />

        <RadioField
          name="eyeColour"
          label="What colour are your eyes?"
          options={eyeColourOptions}
          value={appearance.eyeColour || ""}
          onChange={changeHandlerFor("eyeColour")}
          required
        />

        <RadioField
          name="hairStyle"
          label="How long is your hair?"
          options={hairStyleOptions}
          value={appearance.hairStyle || ""}
          onChange={changeHandlerFor("hairStyle")}
          required
        />

        {appearance.hairStyle !== HairStyle.Bald && (
          <RadioField
            name="hairColour"
            label="What colour is your hair?"
            options={hairColourOptions}
            value={appearance.hairColour || ""}
            onChange={changeHandlerFor("hairColour")}
            required
          />
        )}

        <Button type="submit">Next</Button>
      </Form>
    </HorizontalConstrainer>
  );
};
