const envOrDefault = (key: string, other: string) => {
  const environmentVariableValue = process.env[key];

  if (environmentVariableValue) {
    return environmentVariableValue;
  }

  return other;
};

export const graphqlUrl = envOrDefault(
  "REACT_APP_GRAPHQL_URL",
  "https://api.contact-staging.com/graphql"
);

export const bugsnagApiKey = envOrDefault("REACT_APP_BUGSNAG_API_KEY", "123");
