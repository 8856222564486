import React from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { H1 } from "../components/type/Heading";
import { clearStore, isStored } from "../util/store";
import { HorizontalConstrainer } from "../components/structural/constrainers/HorizontalConstrainer";
import styled from "styled-components";
import { Lede } from "../components/type/Lede";

const Wrapper = styled(HorizontalConstrainer)`
  padding-top: 1rem;
`;

export const ThankYouPage: React.FC<RouteComponentProps> = () => {
  if (
    !isStored("details") ||
    !isStored("measurements") ||
    !isStored("appearance") ||
    !isStored("mediaV2")
  ) {
    return <Redirect to="/details" noThrow />;
  }

  clearStore();

  return (
    <Wrapper>
      <H1>Done!</H1>
      <Lede>Thanks for your application.</Lede>
      <Lede>
        We'll review it and be in touch soon. If you don't hear from us straight
        away, don't panic - we often have a substantial backlog of applications
        to work through.
      </Lede>

      <Lede>
        If you've got any questions or concerns, email{" "}
        <a href="mailto:info@contact.xyz">info@contact.xyz</a>.
      </Lede>
    </Wrapper>
  );
};
