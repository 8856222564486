import { gql } from "@apollo/client";

export const CREATE_ASSET_MUTATION = gql`
  mutation CreateAsset(
    $kind: String!
    $assetableId: ID
    $assetableType: String
    $file: FileUploadInput!
  ) {
    createAsset(
      kind: $kind
      file: $file
      assetableId: $assetableId
      assetableType: $assetableType
    ) {
      id
      mediaUrl
      validUntil
      kind
    }
  }
`;
