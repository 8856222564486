import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import Bugsnag from "@bugsnag/js";
import { graphqlUrl } from "../util/env";

const httpLink = new HttpLink({
  uri: graphqlUrl,
});

const errorReportingLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response.errors) {
      const error = response.errors[0];
      Bugsnag.notify(new Error(error.message), (event) => {
        if (error.path) {
          event.addMetadata("gql", { path: error.path[0] });
        }
      });
    }

    return response;
  });
});

export const graphQLClient = new ApolloClient({
  link: from([errorReportingLink, httpLink]),
  cache: new InMemoryCache(),
});
