import React from "react";
import { RouteComponentProps } from "@reach/router";

import { H1 } from "../components/type/Heading";
import { Lede } from "../components/type/Lede";
import { TextLink } from "../components/clickables/TextLink";
import { HorizontalConstrainer } from "../components/structural/constrainers/HorizontalConstrainer";
import styled from "styled-components";

const Wrapper = styled(HorizontalConstrainer)`
  padding-top: 1rem;
`;

export const NotFoundPage: React.FC<RouteComponentProps> = () => (
  <Wrapper>
    <H1>404</H1>
    <Lede>Uh oh – it looks like the page you're after no longer exists.</Lede>
    <Lede>
      Perhaps you'd like to go <TextLink to="/">home</TextLink>?
    </Lede>
  </Wrapper>
);
