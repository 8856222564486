import React, { useEffect } from "react";
import { Router as ReachRouter, RouteComponentProps } from "@reach/router";

import { LandingPage } from "../pages/LandingPage";
import { NotFoundPage } from "../pages/NotFoundPage";
import { DetailsPage } from "../pages/DetailsPage";
import { MeasurementsPage } from "../pages/MeasurementsPage";
import { AppearancePage } from "../pages/AppearancePage";
import { ThankYouPage } from "../pages/ThankYouPage";
import { MediaPage } from "../pages/MediaPage";
import { SummaryPage } from "../pages/SummaryPage";

const ScrollToTopOnRouteChange: React.FC<RouteComponentProps> = ({
  children,
  location
}) => {
  const pathName = location?.pathname;
  useEffect(() => window.scrollTo(0, 0), [pathName]);
  return <>{children}</>;
};

export const Router = () => (
  <>
    <ReachRouter primary={false}>
      <ScrollToTopOnRouteChange path="/">
        <LandingPage path="/" />
        <DetailsPage path="/details" />
        <MeasurementsPage path="/measurements" />
        <AppearancePage path="/appearance" />
        <MediaPage path="/media" />
        <SummaryPage path="/summary" />
        <ThankYouPage path="/submitted" />
        <NotFoundPage default />
      </ScrollToTopOnRouteChange>
    </ReachRouter>
  </>
);
