import { gql } from "@apollo/client";

export const CREATE_TALENT_APPLICATION_MUTATION = gql`
  mutation CreateTalentApplication(
    $name: String!
    $email: String!
    $gender: Gender!
    $dateOfBirth: ISO8601Date!
    $location: String!
    $city: String!
    $height: Int!
    $waistSize: Int!
    $legLength: Int!
    $shoeSize: Int!
    $chestSize: Int
    $suitSize: Int
    $bustSize: Int
    $hipSize: Int!
    $complexion: Complexion!
    $eyeColour: EyeColour!
    $hairColour: HairColour!
    $hairStyle: HairStyle!
    $instagramUsername: String
    $biography: String!
    $pronouns: Pronouns!
    $hobbies: [String!]
    $assetIds: [ID!]!
  ) {
    createTalentApplication(
      name: $name
      email: $email
      gender: $gender
      dateOfBirth: $dateOfBirth
      location: $location
      city: $city
      height: $height
      waistSize: $waistSize
      legLength: $legLength
      shoeSize: $shoeSize
      chestSize: $chestSize
      suitSize: $suitSize
      bustSize: $bustSize
      hipSize: $hipSize
      complexion: $complexion
      eyeColour: $eyeColour
      hairColour: $hairColour
      hairStyle: $hairStyle
      instagramUsername: $instagramUsername
      biography: $biography
      pronouns: $pronouns
      hobbies: $hobbies
      assetIds: $assetIds
    ) {
      id
    }
  }
`;
