import styled from "styled-components";

export const VerticalConstrainer = styled.div<{
  pinToBottomToSupportLegacyBehaviour?: boolean;
}>`
  min-height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
  margin-top: -65px;
  padding: calc(1.5rem + 65px) 0 1rem;

  ${p =>
    p.pinToBottomToSupportLegacyBehaviour &&
    `
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  `}

  @media (max-width: 800px) {
    padding-top: calc(1rem + 65px);
  }
`;
