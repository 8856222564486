import { Details } from "../interfaces/Details";
import { Measurements } from "../interfaces/Measurements";
import { Appearance } from "../interfaces/Appearance";
import { Media } from "../interfaces/Media";

export const validateMedia = (media?: Media) => {
  return media &&
    media.closeUp &&
    media.fullLength &&
    media.profile &&
    media.showcase &&
    media.waistUp;
}

export const validateMeasurements = (measurements?: Measurements) => {
  return measurements &&
    measurements.height &&
    measurements.hipSize &&
    measurements.legLength &&
    measurements.shoeSize &&
    measurements.waistSize;
}

export const validateAppearance = (appearance?: Appearance) => {
  return appearance &&
    appearance.complexion &&
    appearance.eyeColour &&
    appearance.hairColour &&
    appearance.hairStyle;
}

export const validateDetails = (details?: Details) => {
  return details &&
    details.biography &&
    details.city &&
    details.dateOfBirth &&
    details.email &&
    details.gender &&
    details.location &&
    details.name &&
    details.pronouns;
}

export const computeLastStep = (
  appearance?: Appearance,
  details?: Details,
  measurements?: Measurements,
  media?: Media,
) => {
  if (!validateDetails(details)) {
    return "/details";
  }

  if (!validateMeasurements(measurements)) {
    return "/measurements";
  }

  if (!validateAppearance(appearance)) {
    return "/appearance";
  }

  if (!validateMedia(media)) {
    return "/media";
  }

  return "/summary";
};
