import React, { useState } from "react";
import { RouteComponentProps, navigate, Redirect } from "@reach/router";
import styled from "styled-components";

import { fromStore, toStore, isStored } from "../util/store";

import { Measurements } from "../interfaces/Measurements";

import { Form } from "../components/form/Form";
import { H1 } from "../components/type/Heading";
import { Lede } from "../components/type/Lede";
import { InputField } from "../components/form/InputField";
import { Button } from "../components/clickables/Button";
import { StepSummary } from "../components/structural/StepSummary";
import { HorizontalConstrainer } from "../components/structural/constrainers/HorizontalConstrainer";
import { Gender } from "../graphql/types";

export const MeasurementsPage: React.FC<RouteComponentProps> = () => {
  const [measurements, setMeasurements] = useState<Partial<Measurements>>(
    fromStore("measurements") || {
      height: undefined,
      waistSize: undefined,
      legLength: undefined,
      shoeSize: undefined,
      hipSize: undefined,
      bustSize: undefined,
      chestSize: undefined,
      suitSize: undefined,
    }
  );

  if (!isStored("details")) {
    return <Redirect to="/details" noThrow />;
  }

  const { gender } = fromStore("details") || {};

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    toStore("measurements", measurements as Measurements);

    navigate("/appearance");
  };

  const changeHandlerFor = (fieldName: string) => (nextValue: string) =>
    setMeasurements({
      ...measurements,
      [fieldName]: nextValue === "" ? null : parseInt(nextValue, 10),
    });

  return (
    <HorizontalConstrainer>
      <StepSummary currentStep={2} />
      <H1>Measurements</H1>
      <Lede>
        Next, we need to take a few measurements. We only work in metric, so
        make sure to give this info in centimetres or EU sizing.
      </Lede>
      <Form onSubmit={handleSubmit}>
        <InputField
          type="number"
          name="height"
          label="How tall are you?"
          placeholder="175"
          suffix="cm"
          min="60"
          max="250"
          value={measurements.height?.toString() ?? ""}
          onChange={changeHandlerFor("height")}
          pattern="\d{3}(cm|CM|Cm|cM)?"
          required
        />

        <InputField
          type="number"
          name="waistSize"
          label="What's your waist size?"
          placeholder="85"
          suffix="cm"
          min="30"
          max="200"
          value={measurements.waistSize?.toString() ?? ""}
          onChange={changeHandlerFor("waistSize")}
          pattern="\d{2,3}(cm|CM|Cm|cM)?"
          required
        />

        <InputField
          type="number"
          name="hipSize"
          label="What's your hip size?"
          placeholder="90"
          min="30"
          max="200"
          suffix="cm"
          value={measurements.hipSize?.toString() ?? ""}
          onChange={changeHandlerFor("hipSize")}
          pattern="\d{2,3}(cm|CM|Cm|cM)?"
          required
        />

        <InputField
          type="number"
          name="legLength"
          label="What's your leg length?"
          placeholder="79"
          min="50"
          max="150"
          suffix="cm"
          value={measurements.legLength?.toString() ?? ""}
          onChange={changeHandlerFor("legLength")}
          pattern="\d{2,3}(cm|CM|Cm|cM)?"
          required
        />

        <InputField
          type="number"
          name="shoeSize"
          label="What's your shoe size?"
          placeholder="42"
          suffix="EU"
          min="30"
          max="50"
          value={measurements.shoeSize?.toString() ?? ""}
          onChange={changeHandlerFor("shoeSize")}
          pattern="\d{2}"
          required
        />

        {gender === Gender.NonBinary && (
          <NonBinaryNote>
            <strong>FYI: </strong>
            From this point on, you only need to provide measurements that you
            feel are relevant to you.
          </NonBinaryNote>
        )}

        {gender !== Gender.Male && (
          <InputField
            type="number"
            name="bustSize"
            label="What's your bust size?"
            placeholder="90"
            min="20"
            max="200"
            suffix="cm"
            value={measurements.bustSize?.toString() ?? ""}
            onChange={changeHandlerFor("bustSize")}
            pattern="\d{2,3}(cm|CM|Cm|cM)?"
            required={gender === Gender.Female}
          />
        )}

        {gender !== Gender.Female && (
          <>
            <InputField
              type="number"
              name="chestSize"
              label="What's your chest size?"
              placeholder="90"
              min="30"
              max="200"
              suffix="cm"
              value={measurements.chestSize?.toString() ?? ""}
              onChange={changeHandlerFor("chestSize")}
              pattern="\d{2,3}(cm|CM|Cm|cM)?"
              required={gender === Gender.Male}
            />

            <InputField
              type="number"
              name="suitSize"
              label="What's your suit size?"
              placeholder="90"
              min="30"
              max="200"
              suffix="cm"
              value={measurements.suitSize?.toString() ?? ""}
              onChange={changeHandlerFor("suitSize")}
              pattern="\d{2,3}(cm|CM|Cm|cM)?"
              required={gender === Gender.Male}
            />
          </>
        )}

        <Button type="submit">Next</Button>
      </Form>
    </HorizontalConstrainer>
  );
};

const NonBinaryNote = styled.div`
  margin-bottom: 1rem;
  padding: 0.85rem 1rem;
  background-color: #000;
  color: #fff;
  line-height: 1.65;
  font-size: 0.75rem;
`;
