export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  VerticalFieldValueScalar: any;
};

export type ActiveStorageBlob = {
  __typename?: 'ActiveStorageBlob';
  directUploadHeaders: Array<KeyValue>;
  directUploadUrl: Scalars['String'];
  signedId: Scalars['String'];
};

export type ActiveStorageFileUploadInput = {
  kind: AssetKind;
  signedBlobId: Scalars['ID'];
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  message: Scalars['String'];
  timestamp: Scalars['ISO8601DateTime'];
  username: Scalars['String'];
};

/** The connection type for ActivityLog. */
export type ActivityLogConnection = {
  __typename?: 'ActivityLogConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ActivityLogEdge>>>;
  /** A list of nodes. */
  nodes: Array<ActivityLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ActivityLogEdge = {
  __typename?: 'ActivityLogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ActivityLog;
};

export type AdditionalPayment = {
  __typename?: 'AdditionalPayment';
  alreadyPaid: Scalars['Boolean'];
  amount: Scalars['Float'];
  currency: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  kind: AdditionalPaymentKind;
};

export enum AdditionalPaymentKind {
  AdditionalFee = 'ADDITIONAL_FEE',
  Expense = 'EXPENSE'
}

export type Agency = {
  __typename?: 'Agency';
  agentInvitations?: Maybe<Array<AgentInvitation>>;
  allTalent: Array<User>;
  firstParty: Scalars['Boolean'];
  id: Scalars['ID'];
  location: Scalars['String'];
  logo?: Maybe<Asset>;
  name: Scalars['String'];
  slug: Scalars['String'];
  talent?: Maybe<User>;
};


export type AgencyTalentArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type AgentInvitation = {
  __typename?: 'AgentInvitation';
  agencyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstParty: Scalars['Boolean'];
  id: Scalars['ID'];
  token: Scalars['String'];
};

export type AgentOnboarding = {
  __typename?: 'AgentOnboarding';
  firstParty: Scalars['Boolean'];
};

export type AgentProfile = {
  __typename?: 'AgentProfile';
  id: Scalars['ID'];
  role: Scalars['String'];
};

export type Asset = {
  __typename?: 'Asset';
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  kind: AssetKind;
  mediaUrl: Scalars['String'];
  sortWeight: Scalars['Int'];
  validUntil?: Maybe<Scalars['ISO8601DateTime']>;
  width?: Maybe<Scalars['Int']>;
};

export type AssetFileUploadInput = {
  file?: Maybe<FileUploadInput>;
  kind: AssetKind;
};

export enum AssetKind {
  Featured = 'FEATURED',
  Image = 'IMAGE',
  Logo = 'LOGO',
  Polaroid = 'POLAROID',
  Video = 'VIDEO'
}

export type Auth = {
  __typename?: 'Auth';
  jwt?: Maybe<Scalars['String']>;
  mfaCodeInvalid: Scalars['Boolean'];
  mfaCodeRequired: Scalars['Boolean'];
  unauthorized: Scalars['Boolean'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountNumber?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  kind: BankAccountKind;
  ownerName: Scalars['String'];
  sortCode?: Maybe<Scalars['String']>;
};

export enum BankAccountKind {
  Domestic = 'DOMESTIC',
  International = 'INTERNATIONAL'
}

export type BookerProfile = {
  __typename?: 'BookerProfile';
  company: Scalars['String'];
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export enum Complexion {
  FitzpatrickI = 'FITZPATRICK_I',
  FitzpatrickIi = 'FITZPATRICK_II',
  FitzpatrickIii = 'FITZPATRICK_III',
  FitzpatrickIv = 'FITZPATRICK_IV',
  FitzpatrickV = 'FITZPATRICK_V',
  FitzpatrickVi = 'FITZPATRICK_VI'
}

export type ComplexionOption = {
  __typename?: 'ComplexionOption';
  colour: Scalars['String'];
  description: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Component = {
  __typename?: 'Component';
  name: Scalars['String'];
  props?: Maybe<ComponentProps>;
};

export type ComponentProps = {
  __typename?: 'ComponentProps';
  checkboxOptions?: Maybe<Array<Scalars['String']>>;
  placeholder?: Maybe<Scalars['String']>;
};

export enum ConversionType {
  Centimeters = 'CENTIMETERS',
  EuShoe = 'EU_SHOE',
  FeetAndInches = 'FEET_AND_INCHES',
  Inches = 'INCHES',
  MetersAndCentimeters = 'METERS_AND_CENTIMETERS',
  UkShoe = 'UK_SHOE'
}

export type CreateTalentProfileInput = {
  additionalLocations?: Maybe<Array<TalentLocationInput>>;
  agencyId?: Maybe<Scalars['ID']>;
  assets?: Maybe<Array<ActiveStorageFileUploadInput>>;
  biography?: Maybe<Scalars['String']>;
  contract?: Maybe<FileUploadInput>;
  dateOfBirth: Scalars['ISO8601Date'];
  dietaryRequirements?: Maybe<Array<Scalars['String']>>;
  gender: Gender;
  hobbies?: Maybe<Array<Scalars['String']>>;
  instagramUsername?: Maybe<Scalars['String']>;
  maxDayRate?: Maybe<Scalars['Int']>;
  minDayRate?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pastClients?: Maybe<Array<Scalars['String']>>;
  portfolioItems?: Maybe<Array<PortfolioItemInput>>;
  primaryLocation: TalentLocationInput;
  pronouns?: Maybe<Pronouns>;
  published?: Maybe<Scalars['Boolean']>;
  shrineAssets?: Maybe<Array<AssetFileUploadInput>>;
  vertical: TalentVertical;
  verticalData?: Maybe<Scalars['JSON']>;
  website?: Maybe<Scalars['String']>;
  yearsExperience?: Maybe<Scalars['Int']>;
};

export enum DataType {
  Integer = 'INTEGER',
  String = 'STRING'
}

export type DeckFile = {
  __typename?: 'DeckFile';
  filename: Scalars['String'];
  id: Scalars['ID'];
  url: Scalars['String'];
};

export enum EyeColour {
  Blue = 'BLUE',
  Brown = 'BROWN',
  Green = 'GREEN',
  Veron = 'VERON'
}

export type Field = {
  __typename?: 'Field';
  dataType: DataType;
  excludedGenders?: Maybe<Array<Gender>>;
  filterable: Scalars['Boolean'];
  inputType?: Maybe<InputType>;
  label: Scalars['String'];
  maxValue?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Int']>;
  multiple: Scalars['Boolean'];
  name: Scalars['String'];
  options?: Maybe<Array<FieldOption>>;
  required?: Maybe<Scalars['Boolean']>;
  requiredGenders?: Maybe<Array<Gender>>;
  unit?: Maybe<Scalars['String']>;
};

export type FieldOption = {
  __typename?: 'FieldOption';
  colour?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  value: Scalars['String'];
};

export type FileUploadInput = {
  contentLength?: Maybe<Scalars['Int']>;
  contentType: Scalars['String'];
  filename: Scalars['String'];
  key: Scalars['ID'];
};

export type Filter = {
  __typename?: 'Filter';
  checkedValue?: Maybe<Scalars['String']>;
  childFilters?: Maybe<Array<Filter>>;
  contexts: Array<FilterContext>;
  dataType?: Maybe<DataType>;
  excludedGenders?: Maybe<Array<Gender>>;
  expanded?: Maybe<Scalars['Boolean']>;
  expandedTitleFilter?: Maybe<Filter>;
  helperText?: Maybe<Scalars['String']>;
  imperialMeasurementDisplay?: Maybe<MeasurementDisplay>;
  kind: FilterKind;
  label: Scalars['String'];
  labelChecked?: Maybe<Scalars['String']>;
  labelUnchecked?: Maybe<Scalars['String']>;
  maxValue?: Maybe<Scalars['Int']>;
  metricMeasurementDisplay?: Maybe<MeasurementDisplay>;
  minValue?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  options?: Maybe<Array<FilterOption>>;
  preferenceName?: Maybe<Scalars['String']>;
  rangeLabelPrefix?: Maybe<Scalars['String']>;
  uncheckedValue?: Maybe<Scalars['String']>;
  verticalSpecific: Scalars['Boolean'];
};

export enum FilterContext {
  CreateAJob = 'CREATE_A_JOB',
  TalentManagement = 'TALENT_MANAGEMENT'
}

export enum FilterKind {
  FilterGroup = 'FILTER_GROUP',
  MultiSelect = 'MULTI_SELECT',
  Range = 'RANGE',
  Select = 'SELECT',
  Text = 'TEXT',
  Toggle = 'TOGGLE'
}

export type FilterOption = {
  __typename?: 'FilterOption';
  colour?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  value: Scalars['String'];
};

export type FilterOptions = {
  __typename?: 'FilterOptions';
  bustSize: Measurement;
  chestSize: Measurement;
  complexions: Array<ComplexionOption>;
  dietaryRequirements: Array<LabelValueOption>;
  eyeColours: Array<OptionTypeWithColour>;
  genders: Array<LabelValueOption>;
  hairColours: Array<OptionTypeWithColour>;
  hairStyles: Array<LabelValueOption>;
  height: Measurement;
  hipSize: Measurement;
  hobbies: Array<LabelValueOption>;
  legLength: Measurement;
  shoeSize: Measurement;
  suitSize: Measurement;
  waistSize: Measurement;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  NonBinary = 'NON_BINARY'
}

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  manuallySet: Scalars['Boolean'];
  name: Scalars['String'];
};

export enum HairColour {
  Black = 'BLACK',
  Blond = 'BLOND',
  Brown = 'BROWN',
  Grey = 'GREY',
  Red = 'RED'
}

export enum HairStyle {
  Bald = 'BALD',
  Long = 'LONG',
  Medium = 'MEDIUM',
  Short = 'SHORT'
}

export enum InputType {
  MultiCheckbox = 'MULTI_CHECKBOX',
  MultiSelect = 'MULTI_SELECT',
  Radio = 'RADIO',
  RadioSelect = 'RADIO_SELECT',
  Range = 'RANGE',
  Select = 'SELECT',
  Text = 'TEXT'
}

export type Job = {
  __typename?: 'Job';
  activityStream?: Maybe<ActivityLogConnection>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  affiliate?: Maybe<JobAffiliate>;
  assignedAgent?: Maybe<JobAssignee>;
  /** @deprecated Use assignedAgent instead */
  assignee?: Maybe<User>;
  booker: User;
  brand: Scalars['String'];
  budget: Scalars['Float'];
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']>;
  city?: Maybe<Scalars['String']>;
  /** @deprecated use commissionForUser or totalCommission */
  commission?: Maybe<Scalars['Float']>;
  commissionForUser?: Maybe<Scalars['Float']>;
  conflicts?: Maybe<Array<JobConflict>>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deckFiles?: Maybe<Array<DeckFile>>;
  deliverables: Array<Scalars['String']>;
  description: Scalars['String'];
  endDate: Scalars['ISO8601Date'];
  expiresOn?: Maybe<Scalars['ISO8601DateTime']>;
  finalizedAt?: Maybe<Scalars['ISO8601DateTime']>;
  fullAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imagesReceived?: Maybe<Scalars['Boolean']>;
  /** @deprecated For backwards compatibility */
  inboundPayment?: Maybe<Scalars['String']>;
  invoiceAmount?: Maybe<Scalars['Float']>;
  /** @deprecated replaced by :talent, for backwards compatibility with api types */
  jobTalent: Array<JobTalent>;
  latitude?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  modelsRequired: Scalars['Int'];
  name: Scalars['String'];
  notes?: Maybe<Array<Note>>;
  package?: Maybe<Package>;
  /** @deprecated For backwards compatibility */
  paymentMethod?: Maybe<Scalars['String']>;
  paymentReceived?: Maybe<Scalars['Boolean']>;
  payments?: Maybe<Array<OutboundPayment>>;
  postcode?: Maybe<Scalars['String']>;
  preApprovalShortlistCount?: Maybe<Scalars['Int']>;
  rejectionReason?: Maybe<JobRejectionReason>;
  shortlistCount: Scalars['Int'];
  slug: Scalars['String'];
  spansMultipleDays: Scalars['Boolean'];
  startDate: Scalars['ISO8601Date'];
  state: JobState;
  stateForCurrentUser: JobTalentState;
  talent?: Maybe<Array<JobTalent>>;
  talentRequired: Scalars['Int'];
  totalCommission?: Maybe<Scalars['Float']>;
  transitionableStates: Array<Scalars['String']>;
  usage?: Maybe<Scalars['String']>;
  vertical: TalentVertical;
  virtual: Scalars['Boolean'];
};


export type JobActivityStreamArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum JobAffiliate {
  Depop = 'depop'
}

export type JobAssignee = {
  __typename?: 'JobAssignee';
  assignedLiveJobCount?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  featuredAsset?: Maybe<Asset>;
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type JobConflict = {
  __typename?: 'JobConflict';
  conflictingJobs: Array<Job>;
  jobTalent: JobTalent;
};

export type JobCreationSchema = {
  __typename?: 'JobCreationSchema';
  detailsFormFields: Array<Component>;
};

/** An edge in a connection. */
export type JobEdge = {
  __typename?: 'JobEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Job;
};

export type JobFilterInput = {
  assigneeId?: Maybe<Scalars['ID']>;
  nameOrBrand?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Scalars['String']>>;
};

export enum JobPostCompletionAction {
  ImagesReceived = 'IMAGES_RECEIVED',
  PaymentReceived = 'PAYMENT_RECEIVED'
}

export enum JobRejectionReason {
  Covid_19 = 'COVID_19',
  Generic = 'GENERIC',
  InappropriateBudget = 'INAPPROPRIATE_BUDGET',
  InsufficientInformation = 'INSUFFICIENT_INFORMATION',
  NoTalent = 'NO_TALENT'
}

export enum JobState {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Finalized = 'FINALIZED',
  PendingApproval = 'PENDING_APPROVAL',
  Rejected = 'REJECTED'
}

export type JobTalent = {
  __typename?: 'JobTalent';
  additionalPayments?: Maybe<Array<AdditionalPayment>>;
  assets?: Maybe<Array<Asset>>;
  email?: Maybe<Scalars['String']>;
  firstPaidJob?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  job: Job;
  name: Scalars['String'];
  /** @deprecated backwards compatibility with api types */
  notified?: Maybe<Scalars['Boolean']>;
  notifiedAt?: Maybe<Scalars['ISO8601DateTime']>;
  nudgedAt?: Maybe<Scalars['ISO8601DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profile: TalentProfile;
  state: JobTalentState;
  talent: User;
  transitionableEvents: Array<JobTalentStateEvent>;
  transitionableStates: Array<JobTalentState>;
  userId: Scalars['ID'];
};

export enum JobTalentState {
  Accepted = 'ACCEPTED',
  Confirmed = 'CONFIRMED',
  Disputed = 'DISPUTED',
  Paid = 'PAID',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  RejectedByBooker = 'REJECTED_BY_BOOKER'
}

export enum JobTalentStateEvent {
  Accept = 'ACCEPT',
  Confirm = 'CONFIRM',
  Dispute = 'DISPUTE',
  Pay = 'PAY',
  Reject = 'REJECT',
  RejectByBooker = 'REJECT_BY_BOOKER'
}

/** The connection type for Job. */
export type JobsConnection = {
  __typename?: 'JobsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<JobEdge>>>;
  /** A list of nodes. */
  nodes: Array<Job>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LabelValueOption = {
  __typename?: 'LabelValueOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Measurement = {
  __typename?: 'Measurement';
  maximum: Scalars['Int'];
  minimum: Scalars['Int'];
};

export enum MeasurementDisplay {
  Centimeters = 'CENTIMETERS',
  EuShoe = 'EU_SHOE',
  FeetAndInches = 'FEET_AND_INCHES',
  Inches = 'INCHES',
  MetersAndCentimeters = 'METERS_AND_CENTIMETERS',
  UkShoe = 'UK_SHOE'
}

export type MissingMediaCount = {
  __typename?: 'MissingMediaCount';
  count: Scalars['Int'];
  desired: Scalars['Int'];
  kind: AssetKind;
};

export type Mutation = {
  __typename?: 'Mutation';
  addFailedPaymentToCurrentBatch?: Maybe<OutboundPayment>;
  addJobTalentToCurrentBatch?: Maybe<Array<OutboundPayment>>;
  addPortfolioItem?: Maybe<PortfolioItem>;
  addPortfolioItemAsset?: Maybe<PortfolioItemAsset>;
  addTalent?: Maybe<Job>;
  agentTalentJobResponse?: Maybe<Job>;
  approveJob?: Maybe<Job>;
  assignJob?: Maybe<Job>;
  associateAsset?: Maybe<Asset>;
  cancelJob?: Maybe<Job>;
  changePassword?: Maybe<User>;
  completeJob?: Maybe<Job>;
  convertPackageToJob?: Maybe<Package>;
  createAdditionalPayment?: Maybe<AdditionalPayment>;
  createAgency?: Maybe<Agency>;
  createAgencyTalent?: Maybe<User>;
  createAgencyTalentNote?: Maybe<Note>;
  createAsset?: Maybe<Asset>;
  createBooker?: Maybe<User>;
  createJob?: Maybe<Job>;
  createJobNote?: Maybe<Note>;
  createTalentApplication?: Maybe<TalentApplication>;
  deleteAccount?: Maybe<Scalars['ID']>;
  deleteAdditionalPayment?: Maybe<AdditionalPayment>;
  deleteAgencyTalent?: Maybe<User>;
  deleteAgencyTalentContract?: Maybe<User>;
  deleteAgencyTalentFeaturedAsset?: Maybe<User>;
  deleteAgencyTalentNote?: Maybe<Note>;
  deleteAgencyUser?: Maybe<User>;
  deleteAgentInvitation?: Maybe<AgentInvitation>;
  deleteJobDeckFile?: Maybe<DeckFile>;
  deleteJobNote?: Maybe<Note>;
  deleteJobTalentFromCurrentBatch?: Maybe<JobTalent>;
  deleteOutboundPayment?: Maybe<OutboundPayment>;
  deletePortfolioItem?: Maybe<PortfolioItem>;
  deletePortfolioItemAsset?: Maybe<PortfolioItemAsset>;
  exchangeToken?: Maybe<User>;
  expireJob?: Maybe<Job>;
  finalizeJob?: Maybe<Job>;
  generatePresignedUrl?: Maybe<PresignedUrl>;
  generateUploadUrl?: Maybe<ActiveStorageBlob>;
  inviteUserToAgency?: Maybe<AgentInvitation>;
  jobPostCompletionAction?: Maybe<Job>;
  onboardAgent?: Maybe<AgentOnboarding>;
  onboardTalent?: Maybe<TalentOnboarding>;
  rejectJob?: Maybe<Job>;
  removeJobAssignee?: Maybe<Job>;
  removeTalent?: Maybe<Job>;
  replaceTalent?: Maybe<Job>;
  requestToken?: Maybe<Scalars['String']>;
  resetGroupToDefault?: Maybe<User>;
  sealPaymentBatch?: Maybe<PaymentBatch>;
  sendTalentNudge?: Maybe<Job>;
  sortPortfolioItemAssets?: Maybe<Array<PortfolioItemAsset>>;
  sortPortfolioItems?: Maybe<Array<PortfolioItem>>;
  talentAcceptJob?: Maybe<Job>;
  talentJobResponse?: Maybe<Job>;
  talentRejectJob?: Maybe<Job>;
  updateAdditionalPayment?: Maybe<AdditionalPayment>;
  updateAgency?: Maybe<Agency>;
  updateAgencyTalent?: Maybe<User>;
  updateBankAccount?: Maybe<BankAccount>;
  updateJob?: Maybe<Job>;
  updateOwnUser?: Maybe<User>;
  updatePackage?: Maybe<Package>;
  updatePortfolioItem?: Maybe<PortfolioItem>;
  updatePortfolioItemAsset?: Maybe<PortfolioItemAsset>;
  updateUser?: Maybe<User>;
};


export type MutationAddFailedPaymentToCurrentBatchArgs = {
  outboundPaymentId: Scalars['ID'];
};


export type MutationAddJobTalentToCurrentBatchArgs = {
  jobTalentIds: Array<Scalars['ID']>;
};


export type MutationAddPortfolioItemArgs = {
  categories?: Maybe<Array<Scalars['String']>>;
  clientName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  files?: Maybe<Array<FileUploadInput>>;
  userId: Scalars['ID'];
};


export type MutationAddPortfolioItemAssetArgs = {
  file: FileUploadInput;
  portfolioItemId: Scalars['ID'];
};


export type MutationAddTalentArgs = {
  jobId: Scalars['ID'];
  talentIds: Array<Scalars['ID']>;
};


export type MutationAgentTalentJobResponseArgs = {
  jobId: Scalars['ID'];
  jobTalentId: Scalars['ID'];
  response: JobTalentStateEvent;
};


export type MutationApproveJobArgs = {
  jobId: Scalars['ID'];
};


export type MutationAssignJobArgs = {
  assigneeId: Scalars['ID'];
  jobId: Scalars['ID'];
};


export type MutationAssociateAssetArgs = {
  assetId: Scalars['ID'];
};


export type MutationCancelJobArgs = {
  jobId: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationCompleteJobArgs = {
  jobId: Scalars['ID'];
};


export type MutationConvertPackageToJobArgs = {
  packageId: Scalars['ID'];
};


export type MutationCreateAdditionalPaymentArgs = {
  alreadyPaid: Scalars['Boolean'];
  amount: Scalars['Float'];
  currency: Scalars['String'];
  description: Scalars['String'];
  jobTalentId: Scalars['ID'];
  kind: AdditionalPaymentKind;
};


export type MutationCreateAgencyArgs = {
  location: Scalars['String'];
  logo?: Maybe<FileUploadInput>;
  name: Scalars['String'];
};


export type MutationCreateAgencyTalentArgs = {
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  postcode: Scalars['String'];
  talentProfile: CreateTalentProfileInput;
};


export type MutationCreateAgencyTalentNoteArgs = {
  body: Scalars['String'];
  talentId: Scalars['ID'];
};


export type MutationCreateAssetArgs = {
  assetableId?: Maybe<Scalars['ID']>;
  assetableType?: Maybe<Scalars['String']>;
  file: FileUploadInput;
  kind: Scalars['String'];
};


export type MutationCreateBookerArgs = {
  company: Scalars['String'];
  email: Scalars['String'];
  kind?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};


export type MutationCreateJobArgs = {
  affiliate?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  budget: Scalars['Float'];
  deckFileBlobId?: Maybe<Scalars['ID']>;
  deckFiles?: Maybe<Array<FileUploadInput>>;
  deliverables?: Maybe<Array<Scalars['String']>>;
  description: Scalars['String'];
  endDate: Scalars['ISO8601Date'];
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startDate: Scalars['ISO8601Date'];
  talentIds: Array<Scalars['ID']>;
  talentRequired?: Maybe<Scalars['Int']>;
  usage: Scalars['String'];
  virtual?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateJobNoteArgs = {
  body: Scalars['String'];
  jobId: Scalars['ID'];
};


export type MutationCreateTalentApplicationArgs = {
  assetIds: Array<Scalars['ID']>;
  biography: Scalars['String'];
  bustSize?: Maybe<Scalars['Int']>;
  chestSize?: Maybe<Scalars['Int']>;
  city: Scalars['String'];
  complexion: Complexion;
  dateOfBirth: Scalars['ISO8601Date'];
  email: Scalars['String'];
  eyeColour: EyeColour;
  gender: Gender;
  hairColour: HairColour;
  hairStyle: HairStyle;
  height: Scalars['Int'];
  hipSize: Scalars['Int'];
  hobbies?: Maybe<Array<Scalars['String']>>;
  instagramUsername?: Maybe<Scalars['String']>;
  legLength: Scalars['Int'];
  location: Scalars['String'];
  name: Scalars['String'];
  pronouns: Pronouns;
  shoeSize: Scalars['Int'];
  suitSize?: Maybe<Scalars['Int']>;
  waistSize: Scalars['Int'];
};


export type MutationDeleteAdditionalPaymentArgs = {
  additionalPaymentId: Scalars['ID'];
  jobTalentId: Scalars['ID'];
};


export type MutationDeleteAgencyTalentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAgencyTalentContractArgs = {
  talentId: Scalars['ID'];
};


export type MutationDeleteAgencyTalentFeaturedAssetArgs = {
  talentId: Scalars['ID'];
};


export type MutationDeleteAgencyTalentNoteArgs = {
  noteId: Scalars['ID'];
  talentId: Scalars['ID'];
};


export type MutationDeleteAgencyUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAgentInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteJobDeckFileArgs = {
  deckFileId: Scalars['ID'];
  jobId: Scalars['ID'];
};


export type MutationDeleteJobNoteArgs = {
  jobId: Scalars['ID'];
  noteId: Scalars['ID'];
};


export type MutationDeleteJobTalentFromCurrentBatchArgs = {
  jobTalentId: Scalars['ID'];
};


export type MutationDeleteOutboundPaymentArgs = {
  outboundPaymentId: Scalars['ID'];
};


export type MutationDeletePortfolioItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePortfolioItemAssetArgs = {
  id: Scalars['ID'];
};


export type MutationExchangeTokenArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationExpireJobArgs = {
  jobId: Scalars['ID'];
};


export type MutationFinalizeJobArgs = {
  confirmedTalentIds: Array<Scalars['ID']>;
  jobId: Scalars['ID'];
};


export type MutationGeneratePresignedUrlArgs = {
  attachment: Scalars['String'];
  byteSize: Scalars['Int'];
  checksum: Scalars['String'];
  contentType: Scalars['String'];
  filename?: Maybe<Scalars['String']>;
};


export type MutationGenerateUploadUrlArgs = {
  byteSize: Scalars['Int'];
  checksum: Scalars['String'];
  contentType: Scalars['String'];
  filename?: Maybe<Scalars['String']>;
};


export type MutationInviteUserToAgencyArgs = {
  email: Scalars['String'];
};


export type MutationJobPostCompletionActionArgs = {
  action: JobPostCompletionAction;
  completed: Scalars['Boolean'];
  jobId: Scalars['ID'];
};


export type MutationOnboardAgentArgs = {
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  token: Scalars['String'];
};


export type MutationOnboardTalentArgs = {
  bankAccountAccountNumber?: Maybe<Scalars['String']>;
  bankAccountIban?: Maybe<Scalars['String']>;
  bankAccountKind?: Maybe<BankAccountKind>;
  bankAccountOwnerName?: Maybe<Scalars['String']>;
  bankAccountSortCode?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationRejectJobArgs = {
  jobId: Scalars['ID'];
  rejectionNote?: Maybe<Scalars['String']>;
  rejectionReason: JobRejectionReason;
};


export type MutationRemoveJobAssigneeArgs = {
  jobId: Scalars['ID'];
};


export type MutationRemoveTalentArgs = {
  jobId: Scalars['ID'];
  jobTalentId: Scalars['ID'];
};


export type MutationReplaceTalentArgs = {
  jobId: Scalars['ID'];
  talentIds: Array<Scalars['ID']>;
};


export type MutationRequestTokenArgs = {
  email: Scalars['String'];
};


export type MutationResetGroupToDefaultArgs = {
  talentId: Scalars['ID'];
};


export type MutationSealPaymentBatchArgs = {
  paymentBatchId: Scalars['ID'];
};


export type MutationSendTalentNudgeArgs = {
  jobId: Scalars['ID'];
  jobTalentId: Scalars['ID'];
};


export type MutationSortPortfolioItemAssetsArgs = {
  sortOrder: Array<PortfolioItemAssetSortOrderInput>;
};


export type MutationSortPortfolioItemsArgs = {
  sortOrder: Array<PortfolioSortOrderInput>;
};


export type MutationTalentAcceptJobArgs = {
  jobId: Scalars['ID'];
};


export type MutationTalentJobResponseArgs = {
  jobId: Scalars['ID'];
  jobTalentId: Scalars['ID'];
  response: JobTalentStateEvent;
};


export type MutationTalentRejectJobArgs = {
  jobId: Scalars['ID'];
};


export type MutationUpdateAdditionalPaymentArgs = {
  additionalPaymentId: Scalars['ID'];
  alreadyPaid?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  kind?: Maybe<AdditionalPaymentKind>;
};


export type MutationUpdateAgencyArgs = {
  assetIds?: Maybe<Array<Scalars['Int']>>;
  location?: Maybe<Scalars['String']>;
  logo?: Maybe<FileUploadInput>;
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdateAgencyTalentArgs = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  groupIds?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  talentId: Scalars['ID'];
  talentProfile?: Maybe<UpdateTalentProfileInput>;
};


export type MutationUpdateBankAccountArgs = {
  accountNumber?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  kind?: Maybe<BankAccountKind>;
  ownerName?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateJobArgs = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  affiliate?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deckFileBlobId?: Maybe<Scalars['ID']>;
  deckFiles?: Maybe<Array<FileUploadInput>>;
  deliverables?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  jobId: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  talentRequired?: Maybe<Scalars['Int']>;
  usage?: Maybe<Scalars['String']>;
  virtual?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateOwnUserArgs = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  bookerProfile?: Maybe<UpdateOwnBookerProfileInput>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  featuredAsset?: Maybe<FileUploadInput>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  talentProfile?: Maybe<UpdateOwnTalentProfileInput>;
};


export type MutationUpdatePackageArgs = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deckFiles?: Maybe<Array<FileUploadInput>>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  packageId: Scalars['ID'];
  packageTalent?: Maybe<Array<PackageTalentInput>>;
  postcode?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  talentRequired?: Maybe<Scalars['Int']>;
  usage?: Maybe<Scalars['String']>;
  virtual?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePortfolioItemArgs = {
  categories?: Maybe<Array<Scalars['String']>>;
  clientName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  portfolioItemId: Scalars['ID'];
};


export type MutationUpdatePortfolioItemAssetArgs = {
  portfolioItemAssetId: Scalars['ID'];
  sortWeight: Scalars['Int'];
};


export type MutationUpdateUserArgs = {
  bookerProfile?: Maybe<UpdateBookerProfileInput>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type Note = {
  __typename?: 'Note';
  body: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  user: User;
};

export type NumericAttribute = {
  __typename?: 'NumericAttribute';
  conversionType: ConversionType;
  name: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type OptionTypeWithColour = {
  __typename?: 'OptionTypeWithColour';
  colour: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type OrderingOption = {
  __typename?: 'OrderingOption';
  direction: Scalars['String'];
  field: Scalars['String'];
  label: Scalars['String'];
};

export type OutboundPayment = {
  __typename?: 'OutboundPayment';
  additionalPayments?: Maybe<Array<AdditionalPayment>>;
  amount: Scalars['Float'];
  bankedId?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  id: Scalars['ID'];
  jobTalent?: Maybe<JobTalent>;
  paymentBatch?: Maybe<PaymentBatch>;
  remittanceLetterUrl?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['ISO8601DateTime']>;
  state: OutboundPaymentState;
};

export enum OutboundPaymentState {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Sent = 'SENT'
}

export type Package = {
  __typename?: 'Package';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  booker?: Maybe<PackageBooker>;
  bookerCompany?: Maybe<Scalars['String']>;
  bookerEmail?: Maybe<Scalars['String']>;
  bookerName?: Maybe<Scalars['String']>;
  bookerPhoneNumber?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  deckFiles?: Maybe<Array<DeckFile>>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  jobSlug?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  packageTalent: Array<PackageTalent>;
  postcode?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  startDate?: Maybe<Scalars['ISO8601Date']>;
  state: PackageState;
  talentRequired?: Maybe<Scalars['Int']>;
  usage?: Maybe<Scalars['String']>;
  virtual: Scalars['Boolean'];
};

export type PackageBooker = {
  __typename?: 'PackageBooker';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum PackageState {
  ConvertedToJob = 'CONVERTED_TO_JOB',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type PackageTalent = {
  __typename?: 'PackageTalent';
  id: Scalars['ID'];
  state: PackageTalentState;
  talent: User;
};

export type PackageTalentInput = {
  id: Scalars['ID'];
  state: PackageTalentState;
};

export enum PackageTalentState {
  Optioned = 'OPTIONED',
  Pending = 'PENDING'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PaymentBatch = {
  __typename?: 'PaymentBatch';
  authorizationUrl?: Maybe<Scalars['String']>;
  bankedId?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  outboundPayments: Array<OutboundPayment>;
  sealedAt?: Maybe<Scalars['ISO8601DateTime']>;
  sentAt?: Maybe<Scalars['ISO8601DateTime']>;
  state: PaymentBatchState;
};

export enum PaymentBatchState {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Sealed = 'SEALED',
  Sent = 'SENT'
}

export type PortfolioItem = {
  __typename?: 'PortfolioItem';
  categories: Array<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  portfolioItemAssets: Array<PortfolioItemAsset>;
  sortWeight: Scalars['Int'];
};

export type PortfolioItemAsset = {
  __typename?: 'PortfolioItemAsset';
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  mediaUrl: Scalars['String'];
  sortWeight: Scalars['Int'];
  width?: Maybe<Scalars['Int']>;
};

export type PortfolioItemAssetSortOrderInput = {
  id: Scalars['ID'];
  weight: Scalars['Int'];
};

export type PortfolioItemCategory = {
  __typename?: 'PortfolioItemCategory';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type PortfolioItemInput = {
  categories: Array<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  portfolioItemAssets: Array<FileUploadInput>;
};

export type PortfolioSortOrderInput = {
  id: Scalars['ID'];
  weight: Scalars['Int'];
};

export type PresignedUrl = {
  __typename?: 'PresignedUrl';
  headers: Array<KeyValue>;
  key: Scalars['String'];
  url: Scalars['String'];
};

export type Profile = AgentProfile | BookerProfile | TalentProfile;

export type ProfileAttribute = {
  __typename?: 'ProfileAttribute';
  field: Scalars['String'];
  icon: Scalars['String'];
  label: Scalars['String'];
  verticalSpecific?: Maybe<Scalars['Boolean']>;
};

export enum Pronouns {
  HeHim = 'HE_HIM',
  HeThey = 'HE_THEY',
  SheHer = 'SHE_HER',
  SheThey = 'SHE_THEY',
  TheyThem = 'THEY_THEM',
  ZeHir = 'ZE_HIR'
}

/** The query root of this schema */
export type Query = {
  __typename?: 'Query';
  agentInvitation?: Maybe<AgentInvitation>;
  auth?: Maybe<Auth>;
  booker?: Maybe<User>;
  bookers?: Maybe<UserConnection>;
  filterOptions: FilterOptions;
  /** Find a Job Record */
  job?: Maybe<Job>;
  me?: Maybe<User>;
  package?: Maybe<Package>;
  paymentBatch?: Maybe<PaymentBatch>;
  paymentBatches?: Maybe<Array<PaymentBatch>>;
  talent?: Maybe<User>;
  talentOnboarding?: Maybe<TalentOnboarding>;
  talentSearch?: Maybe<UserConnection>;
  verticalConfigurations?: Maybe<Array<VerticalConfiguration>>;
  verticalTalentSearch?: Maybe<UserConnection>;
};


/** The query root of this schema */
export type QueryAgentInvitationArgs = {
  token: Scalars['String'];
};


/** The query root of this schema */
export type QueryAuthArgs = {
  email: Scalars['String'];
  mfaCode?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};


/** The query root of this schema */
export type QueryBookerArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryBookersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  excludedIds?: Maybe<Array<Scalars['ID']>>;
  first?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  nameOrEmail?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};


/** The query root of this schema */
export type QueryJobArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryPackageArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryPaymentBatchArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryPaymentBatchesArgs = {
  sortColumn?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
};


/** The query root of this schema */
export type QueryTalentArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryTalentOnboardingArgs = {
  token: Scalars['String'];
};


/** The query root of this schema */
export type QueryTalentSearchArgs = {
  after?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['ISO8601Date']>;
  availableTo?: Maybe<Scalars['ISO8601Date']>;
  before?: Maybe<Scalars['String']>;
  bustSize?: Maybe<Array<Scalars['Int']>>;
  chestSize?: Maybe<Array<Scalars['Int']>>;
  complexion?: Maybe<Array<Complexion>>;
  excludedIds?: Maybe<Array<Scalars['Int']>>;
  eyeColour?: Maybe<Array<EyeColour>>;
  first?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  group?: Maybe<TalentSearchGroup>;
  hairColour?: Maybe<Array<HairColour>>;
  hairStyle?: Maybe<Array<HairStyle>>;
  height?: Maybe<Array<Scalars['Int']>>;
  hipSize?: Maybe<Array<Scalars['Int']>>;
  hobbies?: Maybe<Array<Scalars['String']>>;
  last?: Maybe<Scalars['Int']>;
  legLength?: Maybe<Array<Scalars['Int']>>;
  location?: Maybe<Scalars['String']>;
  locationContext?: Maybe<TalentSearchLocationContext>;
  maxAge?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  shoeSize?: Maybe<Array<Scalars['Int']>>;
  sort?: Maybe<Scalars['String']>;
  suitSize?: Maybe<Array<Scalars['Int']>>;
  waistSize?: Maybe<Array<Scalars['Int']>>;
};


/** The query root of this schema */
export type QueryVerticalTalentSearchArgs = {
  after?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['ISO8601Date']>;
  availableTo?: Maybe<Scalars['ISO8601Date']>;
  before?: Maybe<Scalars['String']>;
  category?: Maybe<TalentSearchCategory>;
  excludedIds?: Maybe<Array<Scalars['Int']>>;
  fields?: Maybe<Array<VerticalFieldInput>>;
  first?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  group?: Maybe<TalentSearchGroup>;
  groupId?: Maybe<Scalars['Int']>;
  hobbies?: Maybe<Array<Scalars['String']>>;
  inSameAgency?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  locationContext?: Maybe<TalentSearchLocationContext>;
  maxAge?: Maybe<Scalars['Int']>;
  maxDayRate?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  minDayRate?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Boolean']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
  vertical?: Maybe<TalentVertical>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortOrderInput = {
  column: Scalars['String'];
  direction: SortDirection;
};

export type TalentApplication = {
  __typename?: 'TalentApplication';
  assets: Array<Asset>;
  biography?: Maybe<Scalars['String']>;
  bustSize: Scalars['Int'];
  chestSize: Scalars['Int'];
  city?: Maybe<Scalars['String']>;
  complexion?: Maybe<Complexion>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  email: Scalars['String'];
  eyeColour?: Maybe<EyeColour>;
  gender?: Maybe<Gender>;
  hairColour?: Maybe<HairColour>;
  hairStyle?: Maybe<HairStyle>;
  height?: Maybe<Scalars['Int']>;
  hipSize?: Maybe<Scalars['Int']>;
  hobbies?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  instagramUsername?: Maybe<Scalars['String']>;
  legLength?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pronouns?: Maybe<Pronouns>;
  shoeSize?: Maybe<Scalars['Int']>;
  suitSize: Scalars['Int'];
  waistSize?: Maybe<Scalars['Int']>;
};

export type TalentLocation = {
  __typename?: 'TalentLocation';
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type TalentLocationInput = {
  name: Scalars['String'];
};

export type TalentOnboarding = {
  __typename?: 'TalentOnboarding';
  email: Scalars['String'];
  name: Scalars['String'];
};

export type TalentProfile = {
  __typename?: 'TalentProfile';
  additionalLocations: Array<TalentLocation>;
  age?: Maybe<Scalars['Int']>;
  agency: Agency;
  assets: Array<Asset>;
  biography?: Maybe<Scalars['String']>;
  cardUrl?: Maybe<Scalars['String']>;
  contractUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  dateOfBirth?: Maybe<Scalars['ISO8601DateTime']>;
  dietaryRequirements?: Maybe<Array<Scalars['String']>>;
  gender: Gender;
  hobbies?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  instagramUsername?: Maybe<Scalars['String']>;
  /** @deprecated Please use primary_location instead */
  location: Scalars['String'];
  maxDayRate?: Maybe<Scalars['Int']>;
  mediaCounts?: Maybe<Array<MissingMediaCount>>;
  minDayRate?: Maybe<Scalars['Int']>;
  missingInformation?: Maybe<Array<Scalars['String']>>;
  missingMeasurements?: Maybe<Array<Scalars['String']>>;
  numericAttributes?: Maybe<Array<NumericAttribute>>;
  pastClients?: Maybe<Array<Scalars['String']>>;
  portfolioItem?: Maybe<PortfolioItem>;
  portfolioItems?: Maybe<Array<PortfolioItem>>;
  primaryLocation: TalentLocation;
  pronouns?: Maybe<Pronouns>;
  published?: Maybe<Scalars['Boolean']>;
  scrubbedBiography?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  vertical: TalentVertical;
  verticalData?: Maybe<Array<KeyValue>>;
  website?: Maybe<Scalars['String']>;
  yearsExperience?: Maybe<Scalars['Int']>;
};


export type TalentProfilePortfolioItemArgs = {
  id: Scalars['ID'];
};

export enum TalentSearchCategory {
  Advertising = 'ADVERTISING',
  Advertorial = 'ADVERTORIAL',
  Beauty = 'BEAUTY',
  BlackAndWhite = 'BLACK_AND_WHITE',
  Bts = 'BTS',
  Campaign = 'CAMPAIGN',
  Colour = 'COLOUR',
  Documentary = 'DOCUMENTARY',
  Ecommerce = 'ECOMMERCE',
  Editorial = 'EDITORIAL',
  Event = 'EVENT',
  Landscape = 'LANDSCAPE',
  Lookbook = 'LOOKBOOK',
  MediumFormat = 'MEDIUM_FORMAT',
  Music = 'MUSIC',
  Nature = 'NATURE',
  PersonalWork = 'PERSONAL_WORK',
  Polaroids = 'POLAROIDS',
  Portrait = 'PORTRAIT',
  PressShot = 'PRESS_SHOT',
  Product = 'PRODUCT',
  Sport = 'SPORT',
  StillLife = 'STILL_LIFE',
  Street = 'STREET',
  VirtualReality = 'VIRTUAL_REALITY',
  '35MmFormat' = '_35MM_FORMAT'
}

export enum TalentSearchGroup {
  HiddenProfiles = 'HIDDEN_PROFILES',
  IncompleteMeasurements = 'INCOMPLETE_MEASUREMENTS',
  IncompleteProfiles = 'INCOMPLETE_PROFILES',
  LowMediaCount = 'LOW_MEDIA_COUNT',
  WithoutSignedContract = 'WITHOUT_SIGNED_CONTRACT',
  WithSignedContract = 'WITH_SIGNED_CONTRACT'
}

export enum TalentSearchLocationContext {
  AdditionalOnly = 'ADDITIONAL_ONLY',
  PrimaryAndAdditional = 'PRIMARY_AND_ADDITIONAL',
  PrimaryOnly = 'PRIMARY_ONLY'
}

export enum TalentVertical {
  FashionModel = 'FASHION_MODEL',
  Photographer = 'PHOTOGRAPHER'
}

export type UpdateBookerProfileInput = {
  company?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type UpdateOwnBookerProfileInput = {
  company?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type UpdateOwnTalentProfileInput = {
  additionalLocations?: Maybe<Array<TalentLocationInput>>;
  dietaryRequirements?: Maybe<Array<Scalars['String']>>;
  primaryLocation?: Maybe<TalentLocationInput>;
  pronouns?: Maybe<Pronouns>;
};

export type UpdateTalentProfileInput = {
  additionalLocations?: Maybe<Array<TalentLocationInput>>;
  assets?: Maybe<Array<AssetFileUploadInput>>;
  biography?: Maybe<Scalars['String']>;
  contract?: Maybe<FileUploadInput>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  dietaryRequirements?: Maybe<Array<Scalars['String']>>;
  gender?: Maybe<Gender>;
  hobbies?: Maybe<Array<Scalars['String']>>;
  instagramUsername?: Maybe<Scalars['String']>;
  maxDayRate?: Maybe<Scalars['Int']>;
  minDayRate?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pastClients?: Maybe<Array<Scalars['String']>>;
  primaryLocation?: Maybe<TalentLocationInput>;
  pronouns?: Maybe<Pronouns>;
  published?: Maybe<Scalars['Boolean']>;
  verticalData?: Maybe<Scalars['JSON']>;
  website?: Maybe<Scalars['String']>;
  yearsExperience?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  activityStream?: Maybe<ActivityLogConnection>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  agency?: Maybe<Agency>;
  agencyUsers?: Maybe<Array<User>>;
  agentProfile?: Maybe<AgentProfile>;
  assignedLiveJobCount?: Maybe<Scalars['Int']>;
  availableFeatures?: Maybe<Array<Scalars['String']>>;
  averageJobPay?: Maybe<Scalars['Int']>;
  bankAccount?: Maybe<BankAccount>;
  bookerProfile?: Maybe<BookerProfile>;
  city?: Maybe<Scalars['String']>;
  completedJobCount?: Maybe<Scalars['Int']>;
  convertedPackageCount?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  defaultGroup?: Maybe<Group>;
  email?: Maybe<Scalars['String']>;
  featuredAsset?: Maybe<Asset>;
  groups?: Maybe<Array<Group>>;
  id: Scalars['ID'];
  jobs?: Maybe<JobsConnection>;
  jobsCalendarUrl?: Maybe<Scalars['String']>;
  kinds?: Maybe<Array<Scalars['String']>>;
  lastActionAt?: Maybe<Scalars['ISO8601DateTime']>;
  name: Scalars['String'];
  notes?: Maybe<Array<Note>>;
  packageCount?: Maybe<Scalars['Int']>;
  payments?: Maybe<Array<OutboundPayment>>;
  phoneNumber?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  profile: Profile;
  shortlistCount?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  staffUsers: Array<Maybe<User>>;
  successfulJobCount?: Maybe<Scalars['Int']>;
  talentProfile?: Maybe<TalentProfile>;
  totalPay?: Maybe<Scalars['Int']>;
};


export type UserActivityStreamArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserJobsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filters?: Maybe<JobFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<SortOrderInput>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: User;
};

export type VerticalConfiguration = {
  __typename?: 'VerticalConfiguration';
  cardType: VerticalConfigurationCard;
  fields: Array<Field>;
  filters: Array<Filter>;
  id: TalentVertical;
  image: Scalars['String'];
  jobCreationSchema: JobCreationSchema;
  label: Scalars['String'];
  maxShortlistCount?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  orderingOptions: Array<OrderingOption>;
  portfolioItemCategories: Array<PortfolioItemCategory>;
  profileAttributes: Array<ProfileAttribute>;
};

export enum VerticalConfigurationCard {
  Multiple = 'MULTIPLE',
  Single = 'SINGLE'
}

export type VerticalFieldInput = {
  name: Scalars['String'];
  value: Scalars['VerticalFieldValueScalar'];
};
