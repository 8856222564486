import React from "react";
import styled from "styled-components";
import { BaseFieldProps } from "./BaseField";
import { FieldContainer } from "./FieldContainer";

export interface RadioFieldProps extends BaseFieldProps {
  options: {
    value: string;
    label: string;
    color?: string;
  }[];
  onChange: (nextValue: string) => void;
}

export const RadioField: React.FC<RadioFieldProps> = props => (
  <FieldContainer label={props.label} explainer={props.explainer}>
    {props.options.map(o => (
      <Label key={o.value}>
        <Input
          type="radio"
          name={props.name}
          value={o.value}
          defaultChecked={o.value === props.value}
          onChange={e => props.onChange(e.target.value)}
          required={props.required}
          backgroundColor={o.color}
        />
        <span>{o.label}</span>
      </Label>
    ))}
  </FieldContainer>
);

const Label = styled.label`
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Input = styled.input<{ backgroundColor?: string }>`
  appearance: none;
  width: 1.75rem;
  height: 1.75rem;
  background-color: ${p => p.backgroundColor || "rgb(238, 238, 238)"};
  border-radius: 0;
  margin-right: 0.4rem;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:checked {
    background-color: #000;

    &:before {
      content: " ";
      width: 0.7rem;
      height: 0.7rem;
      display: block;
      background-color: ${p => p.backgroundColor || "#fff"};
    }
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  }

  &:checked + span {
    font-weight: bold;
  }
`;
