export const uploadFileToS3 = async (
  url: string,
  headers: Headers,
  file: File
): Promise<Boolean> => {
  const requestInit: RequestInit = {
    method: "PUT",
    mode: "cors",
    headers: headers,
    body: file,
  };

  const response = await fetch(url, requestInit);

  if (response.ok) {
    return true;
  } else {
    throw new Error("Couldn't upload file");
  }
};
