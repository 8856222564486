import React, { useState } from "react";
import { RouteComponentProps, navigate, Redirect } from "@reach/router";
import { H1 } from "../components/type/Heading";
import { Lede } from "../components/type/Lede";
import { UploadField } from "../components/form/UploadField";
import { StepSummary } from "../components/structural/StepSummary";
import { Media } from "../interfaces/Media";
import { Button } from "../components/clickables/Button";
import { toStore, fromStore, isStored } from "../util/store";
import fullLengthImg from "../assets/mediaExamples/fullLength.jpg";
import waistUpImg from "../assets/mediaExamples/waistUp.jpg";
import closeUpImg from "../assets/mediaExamples/closeUp.jpg";
import profileImg from "../assets/mediaExamples/profile.jpg";
import { Form } from "../components/form/Form";
import { HorizontalConstrainer } from "../components/structural/constrainers/HorizontalConstrainer";

interface RequiredAsset {
  kind: "image" | "video";
  name: keyof Media;
  label: string;
  explainer?: string;
  defaultAssetUrl: string;
}

const requiredAssets: RequiredAsset[] = [
  {
    kind: "image",
    name: "fullLength",
    label: "Full length",
    explainer: "A front-facing photo of your full body, from head to toe",
    defaultAssetUrl: fullLengthImg,
  },
  {
    kind: "image",
    name: "waistUp",
    label: "Waist up",
    explainer: "A front-facing photo of you from the hips upwards",
    defaultAssetUrl: waistUpImg,
  },
  {
    kind: "image",
    name: "closeUp",
    label: "Close Up",
    explainer: "A front-facing portrait of you from the shoulders upwards",
    defaultAssetUrl: closeUpImg,
  },
  {
    kind: "image",
    name: "profile",
    label: "Profile",
    explainer: "A side-on photo from your shoulders upwards",
    defaultAssetUrl: profileImg,
  },
  {
    kind: "video",
    name: "showcase",
    label: "30s Video",
    explainer:
      "Please give us a short intro video stating your name, where you're from and what you're into",
    defaultAssetUrl:
      "https://contact-media.imgix.net/apply/video-example-720.mp4",
  },
];

export const MediaPage: React.FC<RouteComponentProps> = () => {
  const [media, setMedia] = useState<Media>(fromStore("mediaV2") || {});

  if (!isStored("appearance")) {
    return <Redirect to="/appearance" noThrow />;
  }

  const hasAllValues = requiredAssets.every((a) => !!media[a.name]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate("/summary");
  };

  return (
    <HorizontalConstrainer>
      <StepSummary currentStep={4} />
      <H1>Media</H1>
      <Lede>
        We need a few photos and a video to see what you look like. Try to take
        them somewhere with a plain background. Don’t worry – they don’t need to
        be perfect.
      </Lede>
      <Form onSubmit={handleSubmit}>
        {requiredAssets.map((ra) => (
          <UploadField
            key={ra.name}
            value={media[ra.name]}
            onChange={({ asset }) => {
              const storedMedia = fromStore("mediaV2");
              const newMedia = { ...storedMedia, [ra.name]: asset };
              toStore("mediaV2", newMedia);
              setMedia(newMedia);
            }}
            {...ra}
          />
        ))}
        <Button type="submit" disabled={!hasAllValues}>
          Next
        </Button>
      </Form>
    </HorizontalConstrainer>
  );
};
