import React from "react";
import { Header } from "./structural/Header";
import { Footer } from "./structural/Footer";
import { Router } from "./Router";
import { graphQLClient } from "../graphql/client";
import { ApolloProvider } from "@apollo/client";

export const App: React.FC = () => (
  <ApolloProvider client={graphQLClient}>
    <Header />
    <Router />
    <Footer />
  </ApolloProvider>
);
