import React, { useState } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { toStore, fromStore } from "../util/store";
import { Details } from "../interfaces/Details";
import { H1 } from "../components/type/Heading";
import { Lede } from "../components/type/Lede";
import { Form } from "../components/form/Form";
import { FieldContainer } from "../components/form/FieldContainer";
import { Button } from "../components/clickables/Button";
import { InputField } from "../components/form/InputField";
import { RadioField } from "../components/form/RadioField";
import { MultiSelect } from "../components/MultiSelect";
import { StepSummary } from "../components/structural/StepSummary";
import { genderOptions } from "../data/gender";
import { pronounsOptions } from "../data/pronouns";
import { HorizontalConstrainer } from "../components/structural/constrainers/HorizontalConstrainer";
import * as filterOptions from "../data/filterOptions.json";
import { Gender } from "../graphql/types";

const MINIMUM_AGE = 18;

export const DetailsPage: React.FC<RouteComponentProps> = () => {
  const [details, setDetails] = useState<Details>(
    fromStore("details") || {
      name: "",
      email: "",
      dateOfBirth: "",
      gender: Gender.Female,
      pronouns: undefined,
      location: "",
      city: "",
      instagramUsername: "",
      biography: "",
      hobbies: [],
    }
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    toStore("details", details);

    navigate("/measurements");
  };

  const bulkChange = (nextDetails: { [fieldName: string]: string }) =>
    setDetails({ ...details, ...nextDetails });

  const changeHandlerFor = (fieldName: string) => (nextValue: string) =>
    setDetails({ ...details, [fieldName]: nextValue });

  return (
    <HorizontalConstrainer>
      <StepSummary currentStep={1} />

      <H1>Details</H1>

      <Lede>
        For starters, we need some basic information about you. This will help
        us get in touch about your application and make sure you're a good fit
        for Contact.
      </Lede>

      <Form onSubmit={handleSubmit}>
        <InputField
          type="text"
          name="name"
          label="What's your full, legal name?"
          placeholder="Homer Jay Simpson"
          value={details.name}
          onChange={(v) => {
            bulkChange({
              name: v,
              preferredName: v.split(" ")[0],
            });
          }}
          required
        />

        <InputField
          type="email"
          name="email"
          label="What's your email address?"
          placeholder="you@example.com"
          explainer="We'll use this to contact you about your application."
          value={details.email}
          onChange={changeHandlerFor("email")}
          required
        />

        <InputField
          type="text"
          name="dateOfBirth"
          label="When were you born?"
          placeholder="dd/mm/yyyy"
          explainer={
            <strong>
              You have to be at least {MINIMUM_AGE} years old to work with
              Contact.
            </strong>
          }
          value={details.dateOfBirth}
          onChange={(nextValue, event) => {
            let errorMessage = null;
            if (!nextValue.match(/\d{2}\/\d{2}\/\d{4}/)) {
              errorMessage = "Must be in dd/mm/yyyy format";
            }

            const [day, month, year] = nextValue.split("/");

            if (parseInt(day) > 31 || parseInt(month) > 12) {
              errorMessage = "Must be in dd/mm/yyyy format";
            }

            const today = new Date();
            const parsedDate = new Date(`${year}-${month}-${day}`);
            const delta = Math.abs(today.getTime() - parsedDate.getTime());
            const years = new Date(delta).getFullYear() - 1970;
            if (years < MINIMUM_AGE) {
              errorMessage = `You have to be at least ${MINIMUM_AGE} years old to work with Contact.`;
            }

            if (errorMessage !== null) {
              event.target.setCustomValidity(errorMessage);
            } else {
              event.target.setCustomValidity("");
            }

            changeHandlerFor("dateOfBirth")(nextValue);
          }}
          required
        />

        <RadioField
          name="gender"
          label="What gender do you identify as?"
          options={genderOptions}
          value={details.gender}
          onChange={changeHandlerFor("gender")}
          required
        />

        <RadioField
          name="pronouns"
          label="What pronouns do you use?"
          options={pronounsOptions}
          value={details.pronouns || ""}
          onChange={changeHandlerFor("pronouns")}
          required
        />

        <InputField
          type="text"
          name="location"
          label="What country do you live in?"
          placeholder="UK"
          value={details.location}
          onChange={changeHandlerFor("location")}
          required
        />

        <InputField
          type="text"
          name="city"
          label="What city or town do you call home?"
          placeholder="Springfield"
          value={details.city}
          onChange={changeHandlerFor("city")}
          required
        />

        <InputField
          type="text"
          name="instagramUsername"
          label="What's your Instagram username?"
          value={details.instagramUsername}
          onChange={(nextValue: string) => {
            const username = nextValue.replace(/^@/, "");
            setDetails({ ...details, instagramUsername: username });
          }}
          prefix="@"
        />

        <FieldContainer
          label={"What are your hobbies?"}
          explainer="These will appear on your profile if successful."
        >
          <MultiSelect
            initialValues={(details.hobbies || []).map((h) => ({
              value: h,
              label: h,
            }))}
            name="hobbies"
            options={filterOptions.hobbies}
            onChange={(values) => {
              setDetails({ ...details, hobbies: values });
            }}
          />
        </FieldContainer>

        <InputField
          type="textarea"
          name="biography"
          label="A short biography"
          explainer="3-5 sentences from you talking about: Your background, where you're from, occupation (studying/other jobs), interests outside of modelling, and anything you stand for."
          required
          value={details.biography || ""}
          onChange={changeHandlerFor("biography")}
          placeholder="Your biography"
        />

        <Button type="submit">Next</Button>
      </Form>
    </HorizontalConstrainer>
  );
};
