import styled, { css } from "styled-components";

const common = css`
  font-family: "Shapiro Extended", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  line-height: 1;
  max-width: 800px;
  word-wrap: break-word;
`;

export const H1 = styled.h1`
  ${common}
  font-size: 2.5rem;

  @media (max-width: 800px) {
    font-size: 1.75rem;
  }
`;

export const H2 = styled.h2`
  ${common}
  font-size: 1.75rem;

  @media (max-width: 800px) {
    font-size: 1.25rem;
  }
`;
