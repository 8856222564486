import { computeChecksum } from "./checksum";
import { graphQLClient } from "../graphql/client";
import {
  Mutation,
  MutationCreateAssetArgs,
  MutationGeneratePresignedUrlArgs,
} from "../graphql/types";
import {
  CREATE_ASSET_MUTATION,
  GENERATE_PRESIGNED_URL_MUTATION,
} from "../graphql/mutations";
import Bugsnag from "@bugsnag/browser";
import { AssetKind } from "../interfaces/API";

const notifyAndThrow = (error: { name: string; message: string }) => {
  Bugsnag.notify(error);
  throw new Error(error.message);
};

export const generatePresignedUrl = async (file: File) => {
  const checksum = await computeChecksum(file);

  const result = await graphQLClient.mutate<
    Mutation,
    MutationGeneratePresignedUrlArgs
  >({
    mutation: GENERATE_PRESIGNED_URL_MUTATION,
    variables: {
      attachment: "uploads",
      byteSize: file.size,
      checksum: checksum,
      contentType: file.type,
      filename: file.name,
    },
  });

  if (result.errors) {
    notifyAndThrow(result.errors[0]);
  }

  return result.data?.generatePresignedUrl;
};

export const createAsset = async (
  contentType: string,
  filename: string,
  key: string,
  kind: AssetKind
) => {
  const result = await graphQLClient.mutate<Mutation, MutationCreateAssetArgs>({
    mutation: CREATE_ASSET_MUTATION,
    variables: {
      kind: kind,
      file: {
        contentType,
        filename,
        key,
      },
    },
  });

  if (result.errors) {
    notifyAndThrow(result.errors[0]);
  }

  return result.data?.createAsset;
};
