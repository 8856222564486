import React from "react";
import styled, { css } from "styled-components";
import { BaseFieldProps } from "./BaseField";
import { FieldContainer } from "./FieldContainer";

export interface InputFieldProps extends BaseFieldProps {
  type: string;
  placeholder?: string;
  pattern?: string;
  suffix?: string;
  prefix?: string;
  min?: string;
  max?: string;
  onChange: (
    nextValue: string,
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
}

export const InputField: React.FC<InputFieldProps> = (props) => (
  <FieldContainer
    labelFor={props.name}
    label={props.label}
    explainer={props.explainer}
  >
    {props.suffix || props.prefix ? (
      <InputWrapper style={props.prefix ? { paddingLeft: "2rem" } : {}}>
        <InputPrefix>{props.prefix}</InputPrefix>
        {inputElementForProps(props)}
        <InputSuffix>{props.suffix}</InputSuffix>
      </InputWrapper>
    ) : (
      inputElementForProps(props)
    )}
  </FieldContainer>
);

const inputElementForProps = (props: InputFieldProps) => {
  if (props.type === "textarea") {
    return (
      <Textarea
        id={props.name}
        name={props.name}
        placeholder={props.placeholder}
        rows={5}
        onChange={(e) => props.onChange(e.target.value, e)}
        required={props.required}
        value={props.value}
      />
    );
  } else {
    return (
      <Input
        type={props.type}
        id={props.name}
        name={props.name}
        defaultValue={props.value}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value, e)}
        required={props.required}
        pattern={props.pattern}
        min={props.min}
        max={props.max}
      />
    );
  }
};

const baseStyles = css`
  padding: 0.75rem 1rem;
  border: none;
  background-color: rgb(238, 238, 238);
  border-radius: 0;
  width: 100%;
  outline: none;
  position: relative;

  &:focus {
    outline: 4px #000 solid;
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &:invalid:not(:placeholder-shown),
  &:invalid:focus {
    box-shadow: 0 0 0 6px rgba(255, 0, 0, 0.1);
    border-color: #c00;
    outline-color: #c00;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
`;

const Input = styled.input`
  ${baseStyles}
`;

const Textarea = styled.textarea`
  ${baseStyles}
`;

const InputWrapper = styled.div`
  position: relative;
  background-color: rgb(238, 238, 238);
`;

const InputPrefix = styled.span`
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  color: rgba(0, 0, 0, 0.25);
`;

const InputSuffix = styled.span`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  color: rgba(0, 0, 0, 0.25);
`;
