import { Complexion, EyeColour, HairColour, HairStyle } from "../graphql/types";

interface AppearanceOption<T> {
  value: T;
  label: string;
}

interface AppearanceOptionWithColour<T> extends AppearanceOption<T> {
  color: string;
}

const optionLabelForValue =
  <T>(options: AppearanceOption<T>[]) =>
  (value: T) => {
    const option = options.find((o) => o.value === value);

    if (option) {
      return option.label;
    }
  };

export const complexionOptions: AppearanceOptionWithColour<Complexion>[] = [
  { value: Complexion.FitzpatrickI, label: "I", color: "#F4E5C8" },
  { value: Complexion.FitzpatrickIi, label: "II", color: "#E8BB94" },
  { value: Complexion.FitzpatrickIii, label: "III", color: "#ECBC8B" },
  { value: Complexion.FitzpatrickIv, label: "IV", color: "#BB9065" },
  { value: Complexion.FitzpatrickV, label: "V", color: "#8E532D" },
  { value: Complexion.FitzpatrickVi, label: "VI", color: "#523F38" },
];
export const complexionLabelForValue = optionLabelForValue(complexionOptions);

export const eyeColourOptions: AppearanceOptionWithColour<EyeColour>[] = [
  { value: EyeColour.Blue, label: "Blue", color: "#63BEDB" },
  { value: EyeColour.Brown, label: "Brown", color: "#775151" },
  { value: EyeColour.Green, label: "Green", color: "#71A17A" },
  { value: EyeColour.Veron, label: "Veron", color: "#B3C7C6" },
];
export const eyeColourLabelForValue = optionLabelForValue(eyeColourOptions);

export const hairColourOptions: AppearanceOptionWithColour<HairColour>[] = [
  { value: HairColour.Brown, label: "Brown", color: "#6C4B4F" },
  { value: HairColour.Blond, label: "Blonde", color: "#FCFF9A" },
  { value: HairColour.Black, label: "Black", color: "#111" },
  { value: HairColour.Red, label: "Red", color: "#FF0000" },
  { value: HairColour.Grey, label: "Grey", color: "#eee" },
];
export const hairColourLabelForValue = optionLabelForValue(hairColourOptions);

export const hairStyleOptions: AppearanceOption<HairStyle>[] = [
  { value: HairStyle.Short, label: "Short" },
  { value: HairStyle.Medium, label: "Medium" },
  { value: HairStyle.Long, label: "Long" },
  { value: HairStyle.Bald, label: "Bald" },
];

export const hairStyleLabelForValue = optionLabelForValue(hairStyleOptions);
