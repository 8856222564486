import styled from "styled-components";
import { Link } from "@reach/router";

export const Button = styled.button<{ inverted?: boolean }>`
  text-decoration: none;
  font-family: "Shapiro Extended", sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  color: ${props => (props.inverted ? "#000" : "#fff")};
  background-color: ${props => (props.inverted ? "#ddd" : "#000")};
  border: none;
  line-height: 1.25;
  padding: 0.75rem;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  outline: none;
  text-align: center;
  width: 100%;
  display: inline-block;

  &:focus {
    outline: 2px #000 solid;
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 800px) {
    font-size: 1rem;
  }
`;

export const SmallButton = styled(Button)`
  padding: 0.75rem 1rem;
  font-size: 1rem;
  width: initial;
`;

export const ButtonLink = Button.withComponent(Link);
