import React, { useState } from "react";
import styled from "styled-components";
import { BaseFieldProps } from "./BaseField";
import { FieldContainer } from "./FieldContainer";
import { AssetKind } from "../../interfaces/API";
import { uploadFileToS3 } from "../../util/s3";
import { SmallButton } from "../clickables/Button";
import { createAsset, generatePresignedUrl } from "../../util/api";
import { Asset } from "../../graphql/types";

export interface UploadFieldProps extends Omit<BaseFieldProps, "value"> {
  kind: AssetKind;
  value?: Asset;
  defaultAssetUrl: string;
  onChange: (data: { loading: boolean; asset?: Asset; error?: Error }) => void;
}

const acceptForKind = (kind: AssetKind) =>
  ({
    video: ["video/mp4", "video/quicktime", "video/x-matroska"],
    image: ["image/jpeg", "image/png", "image/heic"],
  }[kind].join(","));

export const UploadField: React.FC<UploadFieldProps> = ({
  onChange,
  value,
  defaultAssetUrl,
  kind,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [asset, setAsset] = useState<Asset | undefined>(value);
  const [error, setError] = useState<string | undefined>(undefined);

  // TODO: Refactor this mess to call the `onChange` prop via `useEffect`
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files || !files[0]) {
      return;
    }

    const file = files[0];

    setLoading(true);

    try {
      const presignedUrl = await generatePresignedUrl(file);

      if (!presignedUrl) {
        return;
      }

      const headers = new Headers();
      presignedUrl.headers.forEach((kv) => {
        headers.append(kv.key, kv.value);
      });

      await uploadFileToS3(presignedUrl.url, headers, file);
      const asset = await createAsset(
        file.type,
        file.name,
        presignedUrl.key,
        kind
      );

      setLoading(false);
      setError(undefined);
      setAsset(asset);
      onChange({ loading: false, asset, error: undefined });
    } catch (error) {
      setLoading(false);
      setAsset(undefined);

      if (error instanceof Error) {
        setError(error.message);
        onChange({ loading: false, asset: undefined, error: error });
      }
    }
  };

  const reset = () => {
    setLoading(false);
    setAsset(undefined);
    setError(undefined);
    onChange({ loading: false, asset: undefined, error: undefined });
  };

  return (
    <Wrapper>
      <Controls>
        <FieldContainer
          labelFor={props.name}
          label={props.label}
          explainer={props.explainer}
        >
          {loading && <SmallButton as="div">Uploading...</SmallButton>}
          {!loading && error && (
            <ErrorMessage>
              <strong>Sorry, that didn't work</strong>
              <br />
              <br />
              Please try again, or contact us if the issue persists.
            </ErrorMessage>
          )}
          {!loading && asset && (
            <SmallButton inverted onClick={reset}>
              Remove File
            </SmallButton>
          )}
          {!loading && !asset && (
            <SmallButton as="label">
              <Input
                id={props.name}
                name={props.name}
                type="file"
                accept={acceptForKind(kind)}
                onChange={handleChange}
              />
              Upload File
            </SmallButton>
          )}
        </FieldContainer>
      </Controls>

      {!loading && (
        <PreviewContainer>
          {kind === "image" && (
            <PreviewImage
              src={asset ? asset.mediaUrl : defaultAssetUrl}
              alt=""
            />
          )}
          {kind === "video" && (
            <PreviewVideo
              src={asset ? asset.mediaUrl : defaultAssetUrl}
              controls
            />
          )}
        </PreviewContainer>
      )}
    </Wrapper>
  );
};

const Input = styled.input`
  display: none;
`;

const ErrorMessage = styled.div`
  padding: 0.5rem 0.75rem;
  color: #fff;
  background-color: #c00;
  font-size: 0.75rem;
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const Controls = styled.div`
  width: 70%;
  padding-right: 1rem;
`;

const PreviewContainer = styled.div`
  width: 30%;
  background-color: #eee;
`;

const PreviewImage = styled.img`
  width: 100%;
  display: block;
`;

const PreviewVideo = styled.video`
  width: 100%;
  display: block;
`;
