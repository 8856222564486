import styled from "styled-components";

export const Lede = styled.p`
  margin-bottom: 1rem;
  line-height: 1.65;
  max-width: 800px;

  @media (max-width: 800px) {
    font-size: 0.85rem;
    margin-bottom: 0.75rem;
  }
`;
