import styled from "styled-components";

export const Card = styled.div<{ marginBottom?: string }>`
  padding: 1.5rem;
  margin-bottom: ${p => p.marginBottom || "3rem"};
  border: 4px #000 solid;
  border-bottom-width: 8px;
  background-color: #fff;

  *:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 800px) {
    padding: 1rem;
  }
`;

export const DesktopCard = styled(Card)`
  max-width: 800px;

  @media (max-width: 800px) {
    background-color: transparent;
    padding: 2rem 0 0;
    border: none;
    margin: 0;

    &:first-child {
      padding-top: 0;
    }
  }
`;

export const StaggeredDesktopCard = styled(DesktopCard)`
  &:nth-child(even) {
    margin-left: auto;
  }

  @media (min-width: 1280px) {
    margin-left: -2rem;

    &:nth-child(even) {
      margin-right: -2rem;
    }
  }
`;
