import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import * as serviceWorker from "./serviceWorker";

import "minireset.css";
import "./index.css";

import { App } from "./components/App";
import { normalizeStore } from "./util/store";

import "./util/bugsnag";

normalizeStore();

const ErrorBoundary =
  Bugsnag.getPlugin("react")?.createErrorBoundary(React) || React.Fragment;

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
