import React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { H2 } from "../components/type/Heading";
import { Lede } from "../components/type/Lede";
import { ButtonLink } from "../components/clickables/Button";
import { VerticalConstrainer } from "../components/structural/constrainers/VerticalConstrainer";
import styled from "styled-components";
import { HorizontalConstrainer } from "../components/structural/constrainers/HorizontalConstrainer";
import { StaggeredDesktopCard, Card } from "../components/structural/Card";
import { fromStore, clearStore } from "../util/store";
import { computeLastStep } from "../util/validation";

export const LandingPage: React.FC<RouteComponentProps> = () => {
  const details = fromStore("details");
  const measurements = fromStore("measurements");
  const appearance = fromStore("appearance");
  const media = fromStore("mediaV2");

  const lastStep = computeLastStep(appearance, details, measurements, media);

  return (
    <>
      <div style={{ position: "relative" }}>
        <video
          src="https://contact-media.s3.eu-west-1.amazonaws.com/gloss/landing-background-lbr.mp4"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
            objectFit: "cover",
          }}
          poster="https://contact-media.s3.eu-west-1.amazonaws.com/gloss/landing-background-poster.jpeg"
          autoPlay
          muted
          loop
        ></video>
        <HorizontalConstrainer>
          <VerticalConstrainer pinToBottomToSupportLegacyBehaviour>
            <Card marginBottom="0">
              👋
              <br />
              <br />
              We&apos;re not accepting new applications from unsigned models at
              the moment. If you are signed, and are interested in your agency
              using Contact, do{" "}
              <a href="mailto:shannon@contact.xyz">contact us</a> with details.
            </Card>
          </VerticalConstrainer>
        </HorizontalConstrainer>
      </div>

      <ReasonWrapper>
        <HorizontalConstrainer>
          <StaggeredDesktopCard>
            <H2>Take back control</H2>
            <Lede>
              At Contact, you’re in charge of your time – and you pick the jobs
              you want to do. You can also show casting directors when you’re
              booked so they can work around your schedule.
            </Lede>
          </StaggeredDesktopCard>

          <StaggeredDesktopCard>
            <H2>Join a community</H2>
            <Lede>
              Contact isn’t an agency – it’s a community. You'll meet new people
              from different backgrounds, and you'll form relationships and
              partnerships that last.
            </Lede>
          </StaggeredDesktopCard>

          <StaggeredDesktopCard>
            <H2>Express yourself</H2>
            <Lede>
              You're more than a face. That's why your Contact profile talks
              about your life outside of modelling. When your clients know the
              real you, it builds a stronger working relationship.
            </Lede>
          </StaggeredDesktopCard>

          <StaggeredDesktopCard>
            <H2>Be treated like a professional</H2>
            <Lede>
              We don't accept any inappropriate behaviour from clients. If we
              hear about any issues on set, we'll sort it out. Everyone has the
              right to feel safe at work.
            </Lede>
          </StaggeredDesktopCard>

          <StaggeredDesktopCard>
            <H2>Get paid on time</H2>
            <Lede>
              As soon as you finish the job, we’ll start chasing your payment -
              and send it as soon as we can. Stop worrying about money and get
              back to living.
            </Lede>
          </StaggeredDesktopCard>
        </HorizontalConstrainer>
      </ReasonWrapper>
    </>
  );
};

const ReasonWrapper = styled.div`
  border-top: 2px #000 solid;
  padding: 3rem 0 1rem;
  background-color: #eee;
  background-image: linear-gradient(35deg, rgba(0, 0, 0, 0.1), transparent);

  @media (max-width: 800px) {
    padding: 1.5rem 0;
    background-color: transparent;
    background-image: linear-gradient(35deg, rgba(0, 0, 0, 0.05), transparent);
  }
`;

const StartOver = styled.a`
  cursor: pointer;
  color: #000;
  display: block;
  margin-top: 1rem;
  font-weight: normal;
  font-size: 0.6rem;
  text-decoration: none;

  span {
    font-weight: bold;
    text-decoration: underline;
  }
`;
