import React from "react";
import styled from "styled-components";

const totalSteps = 5;

const Wrapper = styled.div`
  font-size: 0.75rem;
  margin: 1rem 0 0.5rem;
  color: #777;
`;

export const StepSummary: React.FC<{ currentStep: number }> = ({
  currentStep
}) => (
  <Wrapper>
    Step {currentStep} of {totalSteps}
  </Wrapper>
);
