import { Gender } from "../graphql/types";

interface GenderOption {
  value: Gender;
  label: string;
}

export const genderOptions: GenderOption[] = [
  {
    value: Gender.Female,
    label: "Female",
  },
  {
    value: Gender.Male,
    label: "Male",
  },
  {
    value: Gender.NonBinary,
    label: "Non-binary",
  },
];

export const genderLabelForValue = (gender: Gender) => {
  const option = genderOptions.find((o) => o.value === gender);

  if (option) {
    return option.label;
  }
};
