import React from "react";
import styled from "styled-components";
import { HorizontalConstrainer } from "./constrainers/HorizontalConstrainer";

export const Footer = () => (
  <OuterWrapper>
    <InnerWrapper>
      <Link href="https://contact.xyz/terms">Terms</Link>
      <Link href="https://contact.xyz/privacy">Privacy</Link>
      <PointlessCopyrightSign>&copy; {year()}</PointlessCopyrightSign>
    </InnerWrapper>
  </OuterWrapper>
);

const year = () => new Date().getFullYear();

const OuterWrapper = styled.footer`
  padding: 1rem 0;
  font-size: 0.7rem;
  border-top: 2px #000 solid;
`;

const InnerWrapper = styled(HorizontalConstrainer)`
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0.25rem;
  }
`;

const Link = styled.a`
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  margin-right: 1rem;

  @media (max-width: 800px) {
    margin-bottom: 0.5rem;
  }
`;

const PointlessCopyrightSign = styled.div`
  margin-left: auto;

  @media (max-width: 800px) {
    margin-left: initial;
  }
`;
