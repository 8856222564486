import React from "react";
import styled from "styled-components";

export const FieldContainer: React.FC<{
  label: string;
  children: React.ReactNode;
  labelFor?: string;
  explainer?: React.ReactNode;
}> = ({ label, labelFor, children, explainer }) => (
  <Wrapper>
    <Label htmlFor={labelFor}>{label}</Label>
    {explainer && <Explainer>{explainer}</Explainer>}
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const Explainer = styled.div`
  color: #777;
  font-size: 0.75rem;
  line-height: 1.2rem;
  margin-top: -0.25rem;
  margin-bottom: 0.5rem;
`;
