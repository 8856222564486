import React from "react";
import Select, { OptionTypeBase } from "react-select";

interface Option extends OptionTypeBase {
  value: string;
  label: string;
}

type Style = Record<string, any>;

interface MultiSelectProps {
  name: string;
  options?: Option[];
  initialValues?: Option[];
  onChange: (values: string[]) => void;
}

const styles = {
  option: (
    provided: {},
    state: { isFocused: boolean; isSelected: boolean }
  ) => ({
    ...provided,
    backgroundColor: "rgb(238, 238, 238)",
    ":active": {
      backgroundColor: state.isFocused || state.isSelected ? "white" : "black",
    },
  }),
  container: (styles: Style) => ({
    ...styles,
    width: "100%",
  }),
  clearIndicator: (styles: Style) => ({
    ...styles,
    color: "black",
  }),
  dropdownIndicator: (styles: Style) => ({
    ...styles,
    color: "black",
  }),
  menu: (styles: Style) => ({
    ...styles,
    border: "2px solid black",
    zIndex: 20,
  }),
  multiValueLabel: (styles: Style) => ({
    ...styles,
    color: "black",
    padding: "4px 8px",
    backgroundColor: "white",
  }),
  multiValueRemove: (styles: Style) => ({
    ...styles,
    backgroundColor: "white",
  }),
  control: (styles: Style, state: { isFocused: boolean }) => ({
    ...styles,
    width: "100%",
    backgroundColor: "rgb(238, 238, 238)",
    padding: "0.4rem 0.5rem",
    border: "4px solid white !important",
    outline: state.isFocused ? "4px solid black" : "none",
    outlineOffset: state.isFocused ? "-4px" : 0,
    boxShadow: "none",
    ":hover": {
      border: "none",
    },
  }),
  placeholder: (styles: Style) => ({
    ...styles,
    color: "black",
  }),
  valueContainer: (styles: Style) => ({
    ...styles,
    paddingBottom: 8,
    paddingLeft: 8,
  }),
};

export const MultiSelect: React.FC<MultiSelectProps> = ({
  name,
  options,
  onChange,
  initialValues = [],
}) => (
  <Select
    defaultValue={initialValues}
    isMulti
    name={name}
    options={options}
    styles={styles}
    onChange={(value) => {
      if (!value) {
        onChange([]);
      }
      onChange((value as Option[]).map((v) => v.value));
    }}
  />
);
